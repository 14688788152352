<template>
  <div class="layout-container">
    <el-container>
      <el-aside
        class="layout-container-aside "
        :class="menuCollapse?'collapse-aside':''"
      >
        <div class="sidebar-layout">
          <div class="main-left-sidebar">
            <el-header
              class="header-bar"
              @click="goToDashboard"
            >
              <div class="header-bar-box">
                <div
                  v-show="!menuCollapse"
                  class="logo"
                >
                  <el-image
                    class="logo-not-collapse"
                    fit="contain"
                    src="/static/images/logo_long.png"
                  />
                </div>
                <a
                  v-show="menuCollapse"
                  href="#"
                  class="logo"
                >
                  <el-image
                    class="logo-collapse"
                    fit="contain"
                    src="/static/images/logo_short.png"
                  />
                </a>
              </div>
            </el-header>

            <el-menu
              :default-active="menuActiveIndex"
              class="menu-box"
              :collapse="menuCollapse"
              @open="openMenu"
              @close="closeMenu"
            >
              <div
                v-for="(menuItem,menuItemIndex) in menus"
                :key="menuItemIndex"
                class="menu-items-box"
              >
                <el-menu-item
                  v-if="(!menuItem.children || menuItem.children.length === 0) && menuItem.show"
                  :index="menuItem.code"
                  @click="changeNav(menuItem)"
                >
                  <span
                    v-if="menuItem.icon"
                    class="menu-icon"
                  >
                    <i
                      class="menu-item-icon icon ag-iconfont"
                      :class="'icon-'+menuItem.icon"
                    />
                  </span>
                  <template #title>
                    <div class="menu-title">{{ menuItem.title }}</div>
                  </template>
                </el-menu-item>
                <el-sub-menu
                  v-if="menuItem.children && menuItem.children.length > 0 && menuItem.show"
                  :index="menuItem.code"
                >
                  <template #title>
                    <span
                      v-if="menuItem.icon"
                      class="menu-icon"
                    >
                      <i
                        class="menu-item-icon icon ag-iconfont"
                        :class="'icon-'+menuItem.icon"
                      />
                    </span>
                    <div
                      class="menu-title"
                      :class="menuCollapse?'hide-sub-title':''"
                    >{{ menuItem.title }}
                    </div>
                  </template>
                  <div
                    v-for="(subMenuItem,subMenuItemIndex) in menuItem.children"
                    :key="subMenuItemIndex"
                    class="sub-menu-box"
                  >
                    <el-menu-item
                      v-if="(!subMenuItem.children || subMenuItem.children.length === 0) && subMenuItem.show"
                      :index="subMenuItem.code"
                      @click="changeNav(subMenuItem)"
                    >
                      <span
                        v-if="subMenuItem.icon"
                        class="menu-icon"
                      >
                        <i
                          class="menu-item-icon icon ag-iconfont"
                          :class="'icon-'+subMenuItem.icon"
                        />
                      </span>
                      <template #title>
                        <div class="menu-title">{{ subMenuItem.title }}</div>
                      </template>
                    </el-menu-item>
                    <el-sub-menu
                      v-if="subMenuItem.children && subMenuItem.children.length > 0 && subMenuItem.show"
                      :index="subMenuItem.code"
                    >
                      <template #title>
                        <span
                          v-if="subMenuItem.icon"
                          class="menu-icon"
                        >
                          <i
                            class="menu-item-icon icon ag-iconfont"
                            :class="'icon-'+subMenuItem.icon"
                          />
                        </span>
                        <div class="menu-title">{{ subMenuItem.title }}</div>
                      </template>
                      <el-menu-item
                        v-for="(sSubMenuItem,sSubMenuItemIndex) in subMenuItem.children"
                        :key="sSubMenuItemIndex"
                        :index="sSubMenuItem.code"
                        @click="changeNav(sSubMenuItem)"
                      >
                        <span
                          v-if="sSubMenuItem.icon"
                          class="menu-icon"
                        >
                          <i
                            class="menu-item-icon icon ag-iconfont"
                            :class="'icon-'+sSubMenuItem.icon"
                          />
                        </span>
                        <template #title>
                          <div class="menu-title">{{ sSubMenuItem.title }}</div>
                        </template>
                      </el-menu-item>
                    </el-sub-menu>
                  </div>
                </el-sub-menu>
              </div>
            </el-menu>
          </div>
          <div class="bottom-menu-bar">
            <el-popover
              ref="userBarPopover"
              placement="right"
              :width="currTeam.team_roles.length>0&&personTeam.team_id!==currTeam.team_id?450:240"
              trigger="click"
              popper-class="user-bar-el-popover"
            >
              <template #reference>
                <div class="bottom-menu-bar-user">
                  <div class="user-menu-bar-box">
                    <div
                      class="user-avatar"
                    >
                      <div class="avatar-img">
                        <img
                          v-if="$store.getters['user/avatar']"
                          :src="$store.getters['user/avatar']"
                        >
                        <img
                          v-else
                          src="/static/images/avatar/user.png"
                        >
                      </div>
                      <div
                        class="user-info-nick_name"
                        :class="menuCollapse?'collapse-hide':''"
                      >{{
                        $store.getters['user/nick_name'] ? $store.getters['user/nick_name'] : ($store.getters['user/user_name'] ? $store.getters['user/user_name'] : '')
                      }}
                      </div>
                    </div>
                  </div>
                </div>
              </template>
              <div class="user-opt-box">
                <div class="user-opt-body">
                  <div class="user-opt-body-left">
                    <el-menu
                      default-active=""
                      class="team-manage-menu"
                    >
                      <el-menu-item-group title="个人">
                        <el-menu-item
                          class="team-menu-item"
                          @click="switchUserCurrIdentity(personTeam.team_id)"
                        >
                          <div class="avatar-img">
                            <img
                              v-if="$store.getters['user/avatar']"
                              :src="$store.getters['user/avatar']"
                            >
                            <img
                              v-else
                              src="/static/images/avatar/user.png"
                            >
                          </div>
                          <template #title>
                            <div class="team-menu-info">
                              <div class="team-menu-title">{{
                                $store.getters['user/real_name'] ? $store.getters['user/real_name'] : ($store.getters['user/nick_name'] ? $store.getters['user/nick_name'] : '')
                              }}
                              </div>
                              <i
                                v-if="$store.getters['user/current_team_id']==personTeam.team_id"
                                class="icon ag-iconfont icon-true"
                              />
                            </div>
                          </template>
                        </el-menu-item>
                      </el-menu-item-group>
                      <el-menu-item-group title="团队">
                        <el-menu-item
                          v-for="(teamItem,teamItemIndex) in userTeamList"
                          :key="teamItemIndex"
                          class="team-menu-item"
                          @click="switchUserCurrIdentity(teamItem.team_id)"
                        >
                          <div class="avatar-img">
                            <img
                              v-if="teamItem.avatar"
                              :src="teamItem.avatar"
                            >
                            <img
                              v-else
                              src="/static/images/avatar/user.png"
                            >
                          </div>
                          <template #title>
                            <div class="team-menu-info">
                              <div class="team-menu-title">{{ teamItem.name }}</div>
                              <i
                                v-if="$store.getters['user/current_team_id']==teamItem.team_id"
                                class="icon ag-iconfont icon-true"
                              />
                            </div>
                          </template>
                        </el-menu-item>
                        <el-menu-item
                          class="team-menu-item"
                          @click="showDialogCreateTeam()"
                        >
                          <div class="company-add"><i class="icon ag-iconfont icon-plus" /><span>新建团队</span></div>
                        </el-menu-item>
                      </el-menu-item-group>
                    </el-menu>
                    <hr class="user-opt-line">
                    <el-menu
                      default-active=""
                      class="user-manage-menu"
                    >
                      <el-menu-item
                        class="user-manage-menu-item"
                        @click="changeNav({path: '/user-center/user-set',name: 'UserSet'})"
                      >
                        <div class="user-set">
                          <i class="icon ag-iconfont icon-set" /><span>帐号设置</span>
                        </div>

                      </el-menu-item>
                      <el-menu-item
                        class="user-manage-menu-item"
                        @click="userLogout()"
                      >
                        <div class="quit">
                          <i class="icon ag-iconfont icon-exit" /><span>退出登录</span>
                        </div>
                      </el-menu-item>
                    </el-menu>
                  </div>
                  <div
                    v-if="currTeam.team_roles.length>0"
                    class="user-opt-body-right"
                  >
                    <el-menu
                      v-show="$store.getters['user/current_team_id']&&$store.getters['user/current_team_id']!=personTeam.team_id"
                      default-active=""
                      class="r-user-manage-menu"
                    >
                      <el-menu-item
                        v-if="currTeam.team_roles.indexOf('team_manage')>-1"
                        class="r-user-manage-menu-item"
                        @click="changeNav({path: '/org-admin/index',name: 'OrgAdminBase'})"
                      >
                        <div class="r-menu-item-box">
                          <i class="icon ag-iconfont icon-set" /><span>管理后台</span>
                        </div>
                      </el-menu-item>
                      <el-menu-item
                        v-if="currTeam.team_roles.indexOf('team_manage.invitation')>-1"
                        class="r-user-manage-menu-item"
                        @click="showDialogTeamInvitation()"
                      >
                        <div class="r-menu-item-box">
                          <i class="icon ag-iconfont icon-user-add" /><span>邀请成员</span>
                        </div>
                      </el-menu-item>
                    </el-menu>
                    <el-menu
                      v-show="$store.getters['user/current_team_id']&&$store.getters['user/current_team_id']==personTeam.team_id"
                      default-active=""
                      class="person-info"
                    >
                      <!--                      <el-menu-item-->
                      <!--                        class="person-info-item"-->
                      <!--                        @click="showDialogJoinTeam()"-->
                      <!--                      >-->
                      <!--                        <div class="r-menu-item-box">-->
                      <!--                          <i class="icon ag-iconfont icon-team_member" /><span>加入企业</span>-->
                      <!--                        </div>-->
                      <!--                      </el-menu-item>-->

                    </el-menu>
                  </div>
                </div>
              </div>
            </el-popover>
            <div
              class="menu-collapse-opt"
              :class="menuCollapse?'is-collapse-opt':''"
              @click="menuCollapse=!menuCollapse"
            >
              <div class="menu-collapse-btn-box">
                <div class="menu-collapse-btn">
                  <i class="icon ag-iconfont icon-menu" />
                </div>
              </div>

            </div>
          </div>
        </div>
      </el-aside>
      <el-main class="layout-container-main">
        <router-view v-slot="{ Component }">
          <transition
            mode="out-in"
            name="move"
          >
            <component
              :is="Component"
              @changeNav="changeNav"
            />
          </transition>
        </router-view>
      </el-main>
    </el-container>

    <el-dialog
      v-model="dialogTeamInvitation"
      :close-on-click-modal="false"
      width="800px"
      title="团队邀请"
      append-to-body
    >
      <div class="team-invitation-box">
        <TeamInvitation
          ref="teamInvitationDlg"
          :is-dialog="true"
          @confirm-end="confirmTeamInvitation"
        />
      </div>
      <template #footer>
        <span
          class="dialog-footer"
        >
          <el-button
            type="primary"
            @click="submitTeamInvitation()"
          >确 定</el-button>
        </span>
      </template>
    </el-dialog>

    <el-dialog
      v-model="dialogCreateTeam"
      :close-on-click-modal="false"
      width="500px"
      title="创建团队"
      append-to-body
    >
      <div class="createTeamBox">
        <CreateTeam
          ref="createTeamDlg"
          :is-dialog="true"
          @update-team="updateTeam"
        />
      </div>
      <template #footer>
        <span
          class="dialog-footer"
        >
          <el-button @click="closeDialogCreateTeam()">取 消</el-button>
          <el-button
            type="primary"
            @click="submitCreateTeam()"
          >确 定</el-button>
        </span>
      </template>
    </el-dialog>

    <el-dialog
      v-model="dialogJoinTeam"
      :close-on-click-modal="false"
      width="500px"
      title="加入团队"
      append-to-body
    >
      <div class="joinTeamBox">
        <CodeJoinTeam
          ref="joinTeamDlg"
          :is-dialog="true"
          @update-team="updateTeam"
          @close-dlg="closeDialogJoinTeam"
        />
      </div>
      <template #footer>
        <span
          class="dialog-footer"
        >
          <el-button @click="closeDialogJoinTeam()">取 消</el-button>
          <el-button
            type="primary"
            @click="submitJoinTeam()"
          >确 定</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import CreateTeam from '@/views/user_center/components/create_team'
import CodeJoinTeam from '@/views/user_center/components/code_join_team'
import TeamInvitation from '@/views/user_center/components/invitation'
import {getUserTeams} from '@/api/team'
import {switchCurrIdentity} from '@/api/user'

export default {
  name: 'PcLayout',
  components: {
    CreateTeam,
    CodeJoinTeam,
    TeamInvitation
  },
  data() {
    return {
      menus: [
        {
          code: '1',
          name: 'Dashboard',
          show: true,
          icon: 'home',
          title: '发现',
          path: '/dashboard',
          children: []
        },
        {
          code: '2',
          name: 'ChatCreation',
          show: true,
          icon: 'chat',
          title: '对话',
          path: '/chat/creation',
          children: []
        },
        {
          code: '3',
          name: 'Wiki',
          show: true,
          icon: 'knowledge',
          title: '知识库',
          path: '/knowledge/wiki',
          children: []
        }
        /*       {
          code: '4', name: 'Member', show: true, icon: 'user', title: '用户中心', path: '/user-center/member',
          children: [
            {
              code: '4_1',
              name: 'UserSet',
              show: true,
              icon: 'user',
              title: '帐号设置',
              path: '/user-center/user-set',
              children: []
            },
            {
              code: '4_2',
              name: 'TeamInvitation',
              show: true,
              icon: 'user',
              title: '邀请成员',
              path: '/user-center/team-invitation',
              children: []
            },
            {
              code: '4_3',
              name: 'TeamManage',
              show: true,
              icon: 'user',
              title: '团队管理',
              path: '/user-center/team-manage',
              children: []
            }
            /!*  {code: '4_2', name: 'TeamManager', show: true, icon: 'home', title: '团队管理', path: '/knowledge',
                children: [
                  {code: '4_2_1', name: 'UserSet', show: true, icon: 'home', title: '成员管理', path: '/knowledge', children: []},
                  {code: '4_2_2', name: 'UserSet', show: true, icon: 'home', title: '成员加入审批', path: '/knowledge', children: []}
                ]
              }*!/
          ]
        }*/
      ],
      menuActiveIndex: '1',
      menuCollapse: true,
      curPath: '/dashboard',
      hidePopover: false,
      dialogCreateTeam: false,
      userTeamList: [],
      personTeam: {},
      currTeam: {
        team_roles: []
      },
      dialogTeamInvitation: false,
      dialogJoinTeam: false
    }
  },
  mounted() {
    // 处理刷新的菜单
    let path = this.$router.currentRoute.value.path
    if (path.indexOf('/chat/creation') >= 0) {
      // fix bug 处理分享的时候，/chat/creation/:share_code
      path = '/chat/creation'
    }
    // fix bug 页面刷新active错误
    const menu = this.menus.find(o => o.path === path)
    if (menu) {
      this.menuActiveIndex = menu.code
    }
    this.curPath = path
    this.getUserTeam()
  },
  methods: {
    goToDashboard() {
      this.$router.push({name: 'Dashboard'})
    },
    changeNav(item) {
      if (this.$refs['createTeamDlg']) {
        this.$refs['createTeamDlg'].hide()
      }
      this.curPath = item.path
      this.$router.push({path: item.path})
    },
    openMenu(key, keyPath) {
      // console.log(key, keyPath)
    },
    closeMenu(key, keyPath) {
      // console.log(key, keyPath)
    },
    async userLogout() {
      await this.$store.dispatch('user/logout')
      this.$router.push('/login')
    },
    closeDialogCreateTeam() {
      this.dialogCreateTeam = false
    },
    submitCreateTeam() {
      this.$refs['createTeamDlg'].createTeam()
    },
    showDialogCreateTeam() {
      this.dialogCreateTeam = true
      this.$nextTick(() => {
        if (this.$refs['createTeamDlg']) {
          this.$refs['createTeamDlg'].initCreateTeam()
        }
      })
      this.$refs['userBarPopover'].hide()
    },
    closeDialogJoinTeam() {
      this.dialogJoinTeam = false
    },
    submitJoinTeam() {
      this.$refs['joinTeamDlg'].joinTeam()
    },
    showDialogJoinTeam() {
      this.dialogJoinTeam = true
      this.$nextTick(() => {
        if (this.$refs['joinTeamDlg']) {
          this.$refs['joinTeamDlg'].initJoinTeam()
        }
      })
      this.$refs['userBarPopover'].hide()
    },
    updateTeam(id) {
      this.closeDialogCreateTeam()
      this.switchUserCurrIdentity(id)
      this.getUserTeam()
    },
    switchUserCurrIdentity(teamId) {
      if (teamId && this.$store.getters['user/current_team_id'] !== teamId) {
        switchCurrIdentity({current_team_id: teamId}).then(response => {
          if (response && response.code === 0) {
            // 切换身份刷新页面获取数据
            window.location.href = '/'
          }
        }).catch(() => {

        })
      } else {
        this.$refs['userBarPopover'].hide()
      }
    },
    getUserTeam() {
      this.userTeamList = []
      getUserTeams({}).then(response => {
        if (response && response.code === 0) {
          for (let i = 0; i < response.data.length; i++) {
            const item = response.data[i]
            if (item.team_type > 0) {
              this.personTeam = item
            } else {
              this.userTeamList.push(item)
            }
            if (this.$store.getters['user/current_team_id'] === item.team_id) {
              this.currTeam = item
            }
          }
        }
      }).catch(() => {

      })
    },
    showDialogTeamInvitation() {
      this.dialogTeamInvitation = true
      this.$refs['userBarPopover'].hide()
    },
    confirmTeamInvitation() {
      this.dialogTeamInvitation = false
    },
    submitTeamInvitation() {
      this.$refs['teamInvitationDlg'].submitOption()
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/assets/iconfont/iconfont.css';

.layout-container {
  ::v-deep(.el-container) {
    position: relative;
    height: 100%;
  }

  .layout-container-aside {
    background: #030021;
    position: relative;
    transition: width 0.28s;
    overflow: hidden;

    .sidebar-layout {
      background: #030021;

      .main-left-sidebar {
        background: #030021;

        .header-bar {
          .header-bar-box {
            display: flex;
            flex-wrap: nowrap;
            align-content: center;
            align-items: center;
            height: 100%;
            justify-content: space-between;

            .logo {
              .logo-not-collapse {
                width: 100px;
                height: 40px;
              }

              .logo-collapse {
                width: 20px;
                height: 20px;
              }
            }
          }
        }

        .menu-box {
          border-right: none;
          background: #030021;
          color: #ffffff;

          .menu-items-box {
            .menu-item-icon {
              font-size: 20px;
            }

            .menu-icon {
              color: #ffffff;
              width: 22px;
            }

            .hide-sub-title {
              display: none;
            }

            ::v-deep(.el-sub-menu__icon-arrow) {
              right: 10px;
            }

            .menu-title {
              padding-left: 25px;
              color: #ffffff;
            }

            ::v-deep(.el-menu-item) {
              &:hover {
                background: rgba(255, 255, 255, .16);
              }
            }

            ::v-deep(.el-sub-menu) {
              .el-sub-menu__title {
                &:hover {
                  background: rgba(255, 255, 255, .16);
                }
              }
            }

            .sub-menu-box {
              background: #030021;
              color: #ffffff;
            }
          }
        }
      }

      .bottom-menu-bar {
        position: absolute;
        bottom: 20px;
        width: 100%;

        .bottom-menu-bar-user {
          display: flex;
          flex-direction: row;
          align-content: center;
          align-items: center;
          padding: 0 20px;
          height: 56px;

          &:hover {
            cursor: pointer;
            background: rgba(255, 255, 255, .16);
          }

          .user-menu-bar-box {
            display: flex;
            flex-direction: row;
            width: 100%;

            .user-avatar {
              display: flex;
              flex-direction: row;
              align-content: center;
              align-items: center;

              .avatar-img {
                background: #FFFFFF;
                border-radius: 50%;
                width: 25px;
                height: 25px;
                opacity: 1;
                overflow: hidden;
                position: relative;

                img {
                  height: 100%;
                  width: 100%;
                  object-fit: cover;
                  vertical-align: top;
                }
              }

              .user-info-nick_name {
                padding-left: 25px;
                color: #ffffff;
                min-width: 80px;
                overflow: hidden;
              }

              .collapse-hide {
                display: none;
              }
            }
          }
        }

        .menu-collapse-opt {
          padding: 10px 20px;
          display: flex;
          justify-content: flex-end;

          .menu-collapse-btn-box {
            padding: 10px 20px;
            width: 100%;
            justify-content: flex-end;
            display: flex;

            &:hover {
              border-radius: 8px;
              cursor: pointer;
              background: rgba(255, 255, 255, .16);
            }

            .menu-collapse-btn {
              width: 25px;
              height: 25px;
              display: flex;
              align-content: center;
              justify-content: center;
              align-items: center;
              background: #ffffff;
              border-radius: 40px;
            }
          }
        }

        .is-collapse-opt {
          .menu-collapse-btn-box {
            padding: 10px 0;
            width: auto;
          }
        }
      }
    }
  }

  .collapse-aside {
    background: #030021;
    width: 65px;
    overflow: inherit;
  }

  .layout-container-main {
    padding: 0;
  }

  .el-menu-item.is-active {
    background-color: #2C2945;
  }
}

.user-opt-box {
  .user-opt-body {
    background: #fff;
    display: flex;

    .user-opt-body-left {
      flex: none;
      padding-bottom: 4px;
      width: 240px;
      position: relative;

      .team-manage-menu {
        border: none;

        ::v-deep(.el-menu-item-group) {
          margin-top: 12px;
        }

        .team-menu-item {
          height: 50px;

          &:hover {
            background-color: #f7f7f7;
          }

          .avatar-img {
            background: #FFFFFF;
            border-radius: 50%;
            width: 25px;
            height: 25px;
            opacity: 1;
            overflow: hidden;
            position: relative;

            img {
              height: 100%;
              width: 100%;
              object-fit: cover;
              vertical-align: top;
            }
          }

          .team-menu-info {
            display: flex;
            flex-direction: row;
            align-content: center;
            align-items: center;
            justify-content: space-between;
            flex-wrap: nowrap;
            width: 100%;

            .team-menu-title {
              padding-left: 10px;
            }
          }

          .icon-true {
            color: #1b9aee;
          }

          .company-add {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            align-content: center;
            align-items: center;
            color: #1b9aee;

            .icon-plus {
              font-size: 24px;
            }

            span {
              padding-left: 10px;
            }
          }
        }
      }

      .user-opt-line {
        border: 1px solid #f0f0f0;
        margin: 8px 16px;
      }

      .user-manage-menu {
        border: none;

        .user-manage-menu-item {
          height: 50px;

          &:hover {
            background-color: #f7f7f7;
          }

          .user-set {
            .icon {
              font-size: 24px;
            }

            span {
              padding-left: 10px;
            }
          }

          .quit {
            color: #e62412;

            .icon {
              font-size: 24px;
            }

            span {
              padding-left: 10px;
            }
          }
        }
      }
    }

    .user-opt-body-right {
      flex: 1;
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      min-width: 204px;
      padding: 4px 0 20px;
      background-color: #f7f7f7;

      .r-user-manage-menu {
        border: none;

        .r-user-manage-menu-item {
          height: 50px;
          background-color: #f7f7f7;

          &:hover {
            background-color: #f7f7f7;
          }

          .r-menu-item-box {
            .icon {
              font-size: 24px;
            }

            span {
              padding-left: 10px;
            }
          }
        }
      }

      .person-info {
        border: none;

        .person-info-item {
          height: 50px;
          background-color: #f7f7f7;

          &:hover {
            background-color: #f7f7f7;
          }

          .r-menu-item-box {
            .icon {
              font-size: 24px;
            }

            span {
              padding-left: 10px;
            }
          }
        }
      }
    }
  }
}

</style>
