import { createApp } from 'vue'
import ElementPlus from 'element-plus'
import App from './App'
import router from './router'
import store from './store'
import i18n from '@/locales/i18n'
import '@/icons' // icon
import SvgIcon from '@/components/SvgIcon'
import 'element-plus/dist/index.css'
import 'vant/lib/index.css'
import './assets/css/main.css'
import * as common from '@/utils/index.js' // 全局脚本文件
import './permission' // permission control
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import websocket from '@/utils/websocket'
import { createPinia } from 'pinia'
import {
  Button,
  Cell,
  CellGroup,
  ConfigProvider,
  Dialog,
  DropdownItem,
  DropdownMenu,
  Field,
  Form,
  Icon,
  Image,
  List,
  NavBar,
  Notify,
  Picker,
  Popup,
  Search,
  ShareSheet,
  Sticky,
  SwipeCell,
  Switch,
  Tabbar,
  TabbarItem,
  Tag,
  TextEllipsis,
  Uploader
} from 'vant'
import 'v3-waterfall/dist/style.css'
import V3waterfall from 'v3-waterfall'

const app = createApp(App)
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}
app.config.globalProperties.$common = common
app.config.globalProperties.$websocket = websocket
app.component('SvgIcon', SvgIcon)
app.use(createPinia())
app.use(store)
app.use(ConfigProvider)
app.use(Tabbar)
app.use(TabbarItem)
app.use(NavBar)
app.use(Search)
app.use(Cell)
app.use(Image)
app.use(TextEllipsis)
app.use(Notify)
app.use(Picker)
app.use(Popup)
app.use(List)
app.use(Form)
app.use(Field)
app.use(CellGroup)
app.use(Button)
app.use(DropdownMenu)
app.use(Icon)
app.use(DropdownItem)
app.use(Sticky)
app.use(SwipeCell)
app.use(Dialog)
app.use(ShareSheet)
app.use(Switch)
app.use(Uploader)
app.use(Tag)
app.use(V3waterfall)
app.use(router).use(ElementPlus).use(i18n).mount('#app')

