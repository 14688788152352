exports.default = {
  locale: {
    'zh-cn': '簡躰',
    'zh-tw': '繁躰',
    'en-us': '英文'
  },
  router: {
  },
  message: {
  },
  layout: {
  },
  common: {
    please_select: '請選擇',
    please_enter: '請輸入',
    operation: '操作',
    delete: '刪除',
    send: '發送',
    tips: '提示',
    confirm: '確定',
    cancel: '取消',
    submit: '提交',
    other: '其他',
    success: '成功',
    fail: '失敗',
    user: '用護',
    share: '分享',
    sign_in: '登錄',
    copy: '復製'
  }
}
