/**
 * @author AgentStore
 * @description 登录、获取用户信息、退出登录、清除accessToken逻辑，不建议修改
 */
import {getUserInfo, login, logout} from '@/api/user'
import {deepClone} from '@/utils/index'
import websocket from '@/utils/websocket'
import {
  getAccessToken,
  removeAccessToken,
  setAccessToken
} from '@/utils/accessToken'

const state = () => ({
  user_id: null,
  accessToken: getAccessToken(),
  user_name: '',
  nick_name: '',
  real_name: '',
  phone: '',
  avatar: '',
  current_team_id: 0,
  current_team: {},
  has_roles: [],
  roles: [],
  app_role: []
})

const getters = {
  user_id: (state) => state.user_id,
  accessToken: (state) => state.accessToken,
  user_name: (state) => state.user_name,
  nick_name: (state) => state.nick_name,
  real_name: (state) => state.real_name,
  avatar: (state) => state.avatar,
  has_roles: (state) => state.has_roles,
  roles: (state) => state.roles,
  app_role: (state) => state.app_role,
  phone: (state) => state.phone,
  current_team_id: (state) => state.current_team_id,
  current_team: (state) => state.current_team
}

const mutations = {

  /**
   * @author AgentStore
   * @description 设置用户id
   * @param {*} state
   * @param {*} user_id
   */
  setUserId(state, user_id) {
    state.user_id = user_id
  },
  /**
   * @author AgentStore
   * @description 设置token
   * @param {*} state
   * @param {*} accessToken
   */
  setAccessToken(state, accessToken) {
    state.accessToken = accessToken
    setAccessToken(accessToken)
  },
  /**
   * @author AgentStore
   * @description 设置user_name
   * @param {*} state
   * @param {*} user_name
   */
  setUserName(state, user_name) {
    state.user_name = user_name
  },
  /**
   * @author AgentStore
   * @description 设置real_name
   * @param {*} state
   * @param {*} real_name
   */
  setRealName(state, real_name) {
    state.real_name = real_name
  },
  /**
   * @author AgentStore
   * @description 设置nick_name
   * @param {*} state
   * @param {*} nick_name
   */
  setNickName(state, nick_name) {
    state.nick_name = nick_name
  },
  /**
   * @author AgentStore
   * @description 设置nick_name
   * @param {*} state
   * @param {*} phone
   */
  setPhone(state, phone) {
    state.phone = phone
  },
  /**
   * @author AgentStore
   * @description 设置avatar
   * @param {*} state
   * @param {*} avatar
   */
  setAvatar(state, avatar) {
    state.avatar = avatar
  },
  /**
   * @author AgentStore
   * @description 设置has_roles
   * @param {*} state
   * @param {*} has_roles
   */
  setHasRoles(state, has_roles) {
    state.has_roles = has_roles
  },
  /**
   * @author AgentStore
   * @description 设置roles
   * @param {*} state
   * @param {*} roles
   */
  setRoles(state, roles) {
    state.roles = roles
  },
  /**
   * @author AgentStore
   * @description 设置app_role
   * @param {*} state
   * @param {*} app_role
   */
  setAppRole(state, app_role) {
    state.app_role = app_role
  },
  /**
   * @author AgentStore
   * @description 设置current_team_id
   * @param {*} state
   * @param {*} current_team_id
   */
  setCurrentTeamId(state, current_team_id) {
    state.current_team_id = current_team_id
  },
  /**
   * @author AgentStore
   * @description 设置current_team
   * @param {*} state
   * @param {*} current_team
   */
  setCurrentTeam(state, current_team) {
    state.current_team = current_team
  }
}
const actions = {

  /**
   * @author AgentStore
   * @description 获取用户信息接口 这个接口非常非常重要，如果没有明确底层前逻辑禁止修改此方法，错误的修改可能造成整个框架无法正常使用
   * @param {*} { commit, dispatch, state }
   * @returns
   */
  async getUserInfo({commit, dispatch}) {
    return new Promise((resolve, reject) => {
      getUserInfo()
        .then((response) => {
          const {data} = response
          const user = data.user
          if (!data) {
            dispatch('resetAll')
            reject({message: '验证失败，请重新登录...'})
          }
          if (!user.app_role || user.app_role.length === 0) {
            dispatch('resetAll')
            reject({message: '你没有权限！'})
          }
          commit('setAppRole', user.app_role)
          if (!user.has_roles) {
            dispatch('resetAll')
            reject({message: '你没有权限！'})
          }
          const hasRolesData = user.has_roles || []
          commit('setHasRoles', hasRolesData)
          const rolesData = []
          deepClone(hasRolesData, rolesData)
          if (rolesData.length === 0) {
            dispatch('resetAll')
            reject({message: '你没有权限！'})
          }
          commit('setRoles', rolesData)
          const useId = user.id
          const current_team_id = user.current_team_id
          const current_team = user.current_team
          const user_name = user.user_name
          const real_name = user.real_name
          const phone = user.phone
          const avatar = user.avatar ? user.avatar : ''
          const nick_name = user.nick_name ? user.nick_name : (user.phone ? user.phone : user.email)
          commit('setUserId', useId)
          commit('setUserName', user_name)
          commit('setRealName', real_name)
          commit('setPhone', phone)
          commit('setNickName', nick_name)
          commit('setAvatar', avatar)
          commit('setCurrentTeamId', current_team_id)
          commit('setCurrentTeam', current_team)
          resolve(user)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  /**
   * @author AgentStore
   * @description 登录
   * @param {*} { commit }
   * @param {*} userInfo
   */
  login({commit}, userInfo) {
    return new Promise((resolve, reject) => {
      const params = {}
      params.user_name = userInfo.user_name.trim()
      params.password = userInfo.password ? userInfo.password.trim() : ''
      params.valid_code = userInfo.valid_code ? userInfo.valid_code.trim() : ''
      params.login_type = userInfo.login_type ? userInfo.login_type : 0
      params.real_name = userInfo.real_name ? userInfo.real_name : ''
      login(params).then(response => {
        if (response.data && response.data.token) {
          const accessToken = response.data.token
          commit('setAccessToken', accessToken)
          resolve(true)
        } else {
          // ElMessage.error(`登录接口异常，未正确返回api_token...`)
          resolve(false)
        }
      }).catch(error => {
        console.log('error')
        reject(error)
      })
    })
  },

  /**
   * @author AgentStore
   * @description 退出登录
   * @param {*} { dispatch }
   */
  async logout({commit, dispatch}) {
    await logout(state.accessToken)
    await dispatch('resetAll')
    websocket.close()
    localStorage.clear()
    commit('setNickName', '游客')
    commit('setAvatar',
      'https://oss.matchpages.cn/matchpages/common/2021/0909/4909/6139b69d31d40/user_tourist.png'
    )
  },
  async fedLogOut({commit, dispatch}) {
    await dispatch('resetAll')
    localStorage.clear()
  },
  /**
   * @author AgentStore
   * @description 重置accessToken、roles、ability、router等
   * @param {*} { commit, dispatch }
   */
  async resetAll({dispatch}) {
    await dispatch('setAccessToken', '')
    await dispatch('setRoles', '')
    await dispatch('setHasRoles', '')
    await dispatch('setAppRole', '')
    websocket.close()
    removeAccessToken()
  },
  /**
   * @author AgentStore
   * @description 设置token
   */
  setAccessToken({commit}, accessToken) {
    commit('setAccessToken', accessToken)
  },
  /**
   * @author AgentStore
   * @description 设置roles
   */
  setRoles({commit}, accessToken) {
    commit('setRoles', accessToken)
  },
  /**
   * @author AgentStore
   * @description 设置has_roles
   */
  setHasRoles({commit}, accessToken) {
    commit('setHasRoles', accessToken)
  },
  /**
   * @author AgentStore
   * @description 设置app_roles
   */
  setAppRole({commit}, accessToken) {
    commit('setAppRole', accessToken)
  }
}
export default {namespaced: true, state, getters, mutations, actions}
