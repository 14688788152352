exports.default = {
  'link_invitation': '团队链接',
  'qrcode_invitation': '团队二维码',
  'invitation_code': '团队邀请码',
  'platform_invitation': '通过帐号邀请',
  'your_mobile_phone_or_email': '请输入手机或者邮箱',
  'invitation': '邀请',
  'button_to_find_the_user': '点击查询按钮查找用户',
  'mobile_invitation': '手机邀请',
  'mobile_phone': '手机',
  'enter_your_mobile_phone_number': '请输入手机号码',
  'please_enter_email_address': '请输入邮箱地址',
  'copy_succeeded': '复制成功!',
  'the_user_was_not_found': '未查找到该用户',
  'approve_in_the_member_application': '已经提交申请！请在成员申请中审批！',
  'invitation_sent': '已发送邀请！',
  'please_enter_your_mobile_number': '请输入手机号',
  'the_correct_mobile_phone_number': '请输入正确的手机号',
  'invite_you_to_join_the_team': '邀请您加入团队',
  'join_the_team': '加入团队',
  'successfully_joined_the_team': '成功加入团队！',
  'join_now': '立即加入！',
  'please_enter_your_real_name': '请填写你的真实姓名',
  'please_fill_in_the_invitation_form': '请填写邀请手机!',
  'please_fill_in_the_invitation_email': '请填写邀请邮箱!',
  'team_as_soon_as_possible3': '已发送邀请! 请您的小伙伴尽快加入团队队哦！'
}
