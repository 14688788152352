exports.default = {
  locale: {
    'zh-cn': '简体',
    'zh-tw': '繁体',
    'en-us': 'English'
  },
  router: {
    'home': '首页',
    'user-center': '用户中心',
    'user-set': '用户设置',
    'team-invitation': '成员邀请',
    'team-manage': '团队管理',
    'team-set': '团队设置',
    'team-member-department': '成员部门',
    'team-member-approve': '成员审批',
    'chat-creation': 'AI对话',
    'agent-creation': '智能体管理',
    'agent-setting': '智能体设置',
    'agent-link': '连接集成',
    'agent-data-analysis': '数据分析',
    'agent-user-feedback': '用户反馈',
    'agent-member-setting': '成员设置',
    'chat-apps': '智能体中心',
    'chat-export': '导出会话记录',
    'chat-img-mj': 'MidJourney 绘画中心',
    'chat-img-sd': 'Stable Diffusion 绘画中心',
    'chat-images-wall': '作品展示',
    'dashboard': '主页',
    'invitation': '推广计划',
    'knowledge': '知识库',
    'knowledge-manage': '知识库管理'
  },
  message: {},
  layout: {
    bottom_copyright: 'opyright © 厦门循码者信息科技有限公司',
    bottom_icp_no: '闽ICP备2021008034号-1',
    bottom_network_security_no: '',
    bottom_copyright_com: '',
    address_com: ''
  },
  common: {
    please_select: '请选择',
    please_enter: '请输入',
    operation: '操作',
    delete: '删除',
    send: '发送',
    tips: '提示',
    confirm: '确定',
    cancel: '取消',
    submit: '提交',
    other: '其他',
    success: '成功',
    fail: '失败',
    user: '用户',
    share: '分享',
    sign_in: '登 录',
    copy: '复制'
  }
}
