<template>
  <div class="create-team-container">
    <div class="create-team-box">
      <el-form
        ref="teamForm"
        v-loading="saveLoading"
        :model="teamForm"
        :rules="teamRules"
        class="create-team-form"
        label-width="120px"
      >
        <el-form-item
          label="团队名称"
          prop="name"
        >
          <el-input
            v-model="teamForm.name"
            placeholder="请输入团队名称"
          />
        </el-form-item>
        <el-form-item
          label="所属行业"
          prop="industry_id"
        >
          <el-cascader
            v-model="teamForm.industry_id"
            style="width: 100%"
            :options="industryOption"
            :props="industryOptionProps"

            @change="industryChange"
          />
        </el-form-item>
        <el-form-item
          label="公司规模"
          prop="scale"
        >
          <el-select
            v-model="teamForm.scale"
            class="team-scale"
            placeholder="请选择公司规模"
          >
            <el-option
              v-for="item in scaleOption"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </el-form-item>
      </el-form>
    </div>

  </div>
</template>

<script>
import { getTreeIndustry } from '@/api/dict'
import { createTeam } from '@/api/team'

export default {
  name: 'CreateTeam',
  components: {},
  props: {
    isDialog: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      teamForm: {
        name: '',
        industry_id: '',
        scale: ''
      },
      teamRules: {
        name: [
          { required: true, message: '请输入公司名称', trigger: 'blur' },
          { min: 3, max: 100, message: '公司名称长度应大于3个字符小于100字符', trigger: 'blur' }
        ],
        industry_id: [
          { required: true, message: '请选择行业', trigger: 'blur' }
        ],
        scale: [
          { required: true, message: '请选择公司人数', trigger: 'blur' }
        ]
      },
      scaleOption: [
        { label: '1~10人', value: '1~10人' },
        { label: '11~50人', value: '11~50人' },
        { label: '51~100人', value: '51~100人' },
        { label: '101~300人', value: '101~300人' },
        { label: '301~1000人', value: '301~1000人' },
        { label: '1001人及以上', value: '1001人及以上' }
      ],
      industryOption: [],
      industryOptionProps: {
        expandTrigger: 'hover',
        label: 'name',
        value: 'id'
      },
      saveLoading: false
    }
  },
  mounted() {
    this.getIndustry()
  },
  methods: {
    industryChange() {

    },
    createTeam() {
      this.$refs['teamForm'].validate((valid) => {
        if (valid) {
          const params = {}
          params.name = this.teamForm.name
          params.industry_id = this.teamForm.industry_id[this.teamForm.industry_id.length - 1]
          params.scale = 0
          if (this.saveLoading) {
            return false
          }
          this.saveLoading = true
          createTeam(params).then(response => {
            this.saveLoading = false
            if (response && response.code === 0) {
              if (this.isDialog) {
                this.$message.success('团队创建成功！')
                this.$emit('update-team', response.data)
              }
            }
          }).catch(() => {
            this.saveLoading = false
          })
        } else {
          return false
        }
      })
    },
    initCreateTeam() {
      this.teamForm.name = ''
      this.teamForm.industry_id = ''
      this.teamForm.scale = ''
    },
    getIndustry() {
      getTreeIndustry({}).then(response => {
        if (response && response.code === 0) {
          this.industryOption = response.data
        }
      }).catch(() => {

      })
    }
  }
}
</script>

<style lang="scss" scoped>
.create-team-container {
  .create-team-box {
    .create-team-form {

      .team-scale {
        width: 100%;
      }
    }
  }

}
</style>
