import request from '@/utils/request'

/**
 * 获取团队邀请码
 * */
export function getInviteCode(data) {
  return request({
    url: '/api/team/get-invite-code',
    method: 'post',
    data
  })
}
/**
 * 创建团队
 * */
export function createTeam(data) {
  return request({
    url: '/api/team/create-team',
    method: 'post',
    data
  })
}

/**
 * 获取用户团队列表
 * */
export function getUserTeams(data) {
  return request({
    url: '/api/team/get-user-teams',
    method: 'get',
    params: data
  })
}

/**
 * 获取当前团队信息
 * */
export function getCurrTeamInfo(data) {
  return request({
    url: '/api/team/get-curr-team-info',
    method: 'get',
    params: data
  })
}

/**
 * 获取团队信息
 * */
export function getTeamInfoByInviteCode(data) {
  return request({
    url: '/api/team/get-team-info-by-code',
    method: 'get',
    params: data
  })
}

/**
 * 团队邀请码加入团队
 * */
export function joinTeam(data) {
  return request({
    url: '/api/team/join-team',
    method: 'post',
    data
  })
}

/**
 * 团队手机或者邮箱邀请加入团队
 * */
export function inviteJoinTeam(data) {
  return request({
    url: '/api/team/invite-team',
    method: 'post',
    data
  })
}

/**
 * 更新团队基础信息
 * */
export function updateBaseTeam(data) {
  return request({
    url: '/api/team/update-team',
    method: 'post',
    data
  })
}

/**
 * 解散团队
 * */
export function delTeam(data) {
  return request({
    url: '/api/team/del-team',
    method: 'post',
    data
  })
}

/**
 * 获取当前团队成员
 * */
export function listTeamUsers(data) {
  return request({
    url: '/api/team/user-list',
    method: 'get',
    params: data
  })
}

/**
 * 转移团队
 * */
export function turnOver(data) {
  return request({
    url: '/api/team/turn-over',
    method: 'post',
    data
  })
}

/**
 * 获取部门列表
 * */
export function getTeamDepartmentList(data) {
  return request({
    url: '/api/team/department-list',
    method: 'get',
    params: data
  })
}

/**
 * 创建团队部门
 * */
export function createDepartment(data) {
  return request({
    url: '/api/team/create-department',
    method: 'post',
    data
  })
}
/**
 * 保存团队部门
 * */
export function saveDepartment(data) {
  return request({
    url: '/api/team/save-department',
    method: 'post',
    data
  })
}

/**
 * 删除部门
 * */
export function delDepartment(data) {
  return request({
    url: '/api/team/del-department',
    method: 'delete',
    data
  })
}
/**
 * 部门排序
 * */
export function changeDepartmentSort(data) {
  return request({
    url: '/api/team/department-sort',
    method: 'post',
    data
  })
}

/**
 * 批量编辑
 * */
export function batchSaveDepartment(data) {
  return request({
    url: '/api/team/batch-save-department',
    method: 'post',
    data
  })
}

/**
 * 部门重新排序
 * */
export function reSortDepartment(data) {
  return request({
    url: '/api/team/re-sort-department',
    method: 'post',
    data
  })
}

/**
 * 获取当前团队部门成员
 * */
export function getDepartmentMemberList(data) {
  return request({
    url: '/api/team/department-member-list',
    method: 'get',
    params: data
  })
}

/**
 * 变更成员部门
 * */
export function changeMemberDepartment(data) {
  return request({
    url: '/api/team/change-member-department',
    method: 'post',
    data
  })
}

/**
 * 移除团队成员
 * */
export function removeOutTeam(data) {
  return request({
    url: '/api/team/remove-out-team',
    method: 'delete',
    data
  })
}

/**
 * 团队成员离职列表
 * */
export function getLeaveMemberList(data) {
  return request({
    url: '/api/team/leave-member-list',
    method: 'get',
    params: data
  })
}

/**
 * 删除离职人员记录
 * */
export function delLeaveMember(data) {
  return request({
    url: '/api/team/del-leave-member',
    method: 'delete',
    data
  })
}

/**
 * 离职人员复员
 * */
export function restoreLeaveMember(data) {
  return request({
    url: '/api/team/restore-leave-member',
    method: 'post',
    data
  })
}

/**
 * 申请审核
 * */
export function applyStatusSet(data) {
  return request({
    url: '/api/team/apply-status-set',
    method: 'post',
    data
  })
}

