exports.default = {
  locale: {
    'zh-cn': '简体',
    'zh-tw': '繁体',
    'en-us': 'English'
  },
  router: {
  },
  message: {
  },
  layout: {
  },
  common: {
    please_select: 'Please Select',
    please_enter: 'Please enter',
    operation: 'Operation',
    delete: 'Delete',
    send: 'Send',
    tips: 'Tips',
    confirm: 'Confirm',
    cancel: 'Cancel',
    submit: 'Submit',
    other: 'Other',
    success: 'Success',
    fail: 'Fail',
    user: 'User',
    share: 'Share',
    sign_in: 'Sign In',
    copy: 'Copy'
  }
}
