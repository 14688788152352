/**
 * @author AgentStore
 * @description 路由守卫，目前两种模式：all模式与intelligence模式
 */
import router from '@/router'
import store from '@/store'
import {ElMessage} from 'element-plus'
import i18n, {getLocale, initLocale} from '@/locales/i18n'
import getPageTitle from '@/utils/pageTitle'
import {routesWhiteList} from '@/settings'

// token失效回退到登录页时是否记录本次的路由
const recordRoute = true

function hasPermission(roles, permissionRoles) {
  if (roles.indexOf('is_super') >= 0) return true // admin permission passed directly
  if (!permissionRoles) return true
  return roles.some((role) => permissionRoles.indexOf(role) >= 0)
}

router.beforeEach(async(to, from, next) => {
  await initLocale(getLocale())
  const hasToken = store.getters['user/accessToken']
  if (hasToken) {
    if (to.path === '/login') {
      next({path: '/'})
    } else {
      const roles = store.getters['user/roles']
      const hasRoles = roles && roles.length > 0
      if (hasRoles) {
        // 增加权限判断
        if (hasPermission(roles, to.meta.roles) && to.name) {
          next()
        } else {
          next()
          next({
            path: '/404',
            replace: true,
            query: {
              noGoBack: true
            }
          })
        }
      } else {
        try {
          const {has_roles} = await store.dispatch('user/getUserInfo')
          // generate accessible routes map based on roles
          const accessRoutes = await store.dispatch('permission/generateRoutes', has_roles)
          // dynamically add accessible routes
          accessRoutes.forEach((item) => {
            router.addRoute(item)
          })
          // hack method to ensure that addRoutes is complete
          // set the replace: true, so the navigation will not leave a history record
          next({...to, replace: true})
        } catch (error) {
          if (error && error.message) {
            let message = error.message
            if (message === 'Network Error') {
              message = '后端接口连接异常'
            }
            ElMessage.error(message)
          }
          await store.dispatch('user/resetAll')
          next(`/login?redirect=${to.path}`)
        }
      }
    }
  } else {
    if (
      routesWhiteList.indexOf(to.path) !== -1 ||
      (to.matched &&
        to.matched[0] &&
        routesWhiteList.indexOf(to.matched[0].path) !== -1)
    ) {
      next()
    } else {
      if (recordRoute) {
        next({path: '/login', query: {redirect: to.path}, replace: true})
      } else {
        next({path: '/login', replace: true})
      }
    }
  }
})
router.afterEach(async(to) => {
  const title = to.meta.title || ''
  document.title = getPageTitle(i18n.global.t(title))
})
