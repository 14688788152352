import {Centrifuge} from 'centrifuge'
import {getWSSubscribeChatToken, getWSToken,getWSComChatChannel,getWSSubscribeComChatToken} from '@/api/websocket'

const host = process.env.VUE_APP_WS_HOST

export async function getWSClientToken() {
  const token = await new Promise((resolve, reject) => {
    getWSToken()
      .then((response) => {
        let token = ''
        if (response && response.code === 0) {
          token = response.data
        }
        //reject({message: '你没有权限！'})
        resolve(token)
      })
      .catch((error) => {
        reject('')
      })
  })
  return token;
}

export async function subscribeChatChannel(channelParams) { // 订阅频道
  const subToken = await new Promise((resolve, reject) => {
    let conversationId = getConversationId(channelParams.channel)
    getWSSubscribeChatToken({conversation_id: conversationId})
      .then((response) => {
        let token = ''
        if (response && response.code === 0) {
          token = response.data
        }
        // reject({message: '你没有权限！'})
        resolve(token)
      })
      .catch((error) => {
        reject('')
      })
  })
  return subToken
}


export async function getComChatChannel(comAgentKey) { // 获取公共智能体订阅频道
  const comAgentChannel = await new Promise((resolve, reject) => {
    getWSComChatChannel({com_agent_key: comAgentKey})
      .then((response) => {
        let channel = ''
        if (response && response.code === 0) {
          channel = response.data
        }
        // reject({message: '你没有权限！'})
        resolve(channel)
      })
      .catch((error) => {
        reject('')
      })
  })
  return comAgentChannel
}

export async function getSubscribeComChatToken(channelParams) { // 订阅公共智能体频道token
  const subToken = await new Promise((resolve, reject) => {
    getWSSubscribeComChatToken({channel:channelParams.channel })
      .then((response) => {
        let token = ''
        if (response && response.code === 0) {
          token = response.data
        }
        // reject({message: '你没有权限！'})
        resolve(token)
      })
      .catch((error) => {
        reject('')
      })
  })
  return subToken
}



export function getChatChannel(conversationId) {
  let appName = process.env.VUE_APP_SUBSCRIBE_APP_NAME
  let channel = appName + '-agent-chat-' + conversationId
  return channel
}

export function getConversationId(channel) {
  let appName = process.env.VUE_APP_SUBSCRIBE_APP_NAME
  let conversationId = channel.replace(appName + '-agent-chat-', '')
  return conversationId
}


// 客户端连接有4种状态：
/*
connect()– 连接到服务器
disconnect()- 与服务器断开连接
close()- 如果不再需要，请关闭客户端
send(data)- 向服务器发送异步消息
rpc(method, data)- 发送任意RPC并等待响应
disconnected 当一个新的客户端被创建时，它就有一个disconnected状态
connecting 要连接到服务器，connect()必须调用方法。调用connect后，Client移动到该connecting状态。
connected 如果客户端无法连接到服务器，它会尝试使用指数退避算法（具有完全抖动）创建连接。如果与服务器的连接成功，则状态变为connected。
closed closedSDK 中实现的状态，当不再需要客户端时，必须清理内部队列、线程执行器等资源。所有订阅都应在关闭时自动进入unsubscribed状态。进入某个closed状态后客户端就无法使用了。
*/
/*
订阅有3种状态：
unsubscribed 当创建新的订阅时，它具有一个unsubscribed状态。
subscribing 要启动订阅通道的实际过程，subscribe()应该调用 Subscription 实例的方法。调用 Subscription 后subscribe()进入subscribing状态。
subscribed 如果订阅频道不成功，则根据错误类型，订阅可以自动重新订阅（使用指数退避）或进入某个unsubscribed状态（出现非临时错误）。如果频道订阅成功，则状态变为subscribed
*/
// 协议 目前分为 系统SYS 通知 业务 Agent
const PROTOCOLS = {}
// 公共智能体
const ComAgent={
  COUNSELOR_OPTIMIZE:'COUNSELOR_OPTIMIZE',
  PROMPT_OPTIMIZE:'PROMPT_OPTIMIZE',
  AVATAR_IMG_BUILD:'AVATAR_IMG_BUILD',
}
// 应用私有协议（通过消息队列传递）
const websocket = {
  socketClient: null,//websocket client
  isInit: false, // 标记是否初始化
  heartbeat: new Date().getTime(), // 心跳时间 ，通过心跳时间刷新token
  vm: null, // 初始化 客户端的页面可以拿到页面的一些参数
  protocols: PROTOCOLS,
  comAgent:ComAgent,
  channelList: { },
  init(vm) {
    if (!this.isInit) {
      this.isInit = true
      this.vm = vm
      this.contact()
    }
  },
  close() {
    // console.log('11111')
    this.isInit = false
    if (this.socketClient) {
      this.socketClient.disconnect() //断开服务
      Object.keys(this.channelList).forEach(key => {
        this.channelList[key].unsubscribe()
      });
      // console.log('22222')
      this.channelList= {}
    }
    if (this.retryTimer) {
      clearTimeout(this.retryTimer)
    }
  },
  contact() {
    const self = this
    const token = this.vm.$store.getters['user/accessToken']
    if (!token) {
      if (self.isInit) {
        this.recontact()
      }
      return false
    }
    let url = host + '/connection/websocket'

    this.socketClient = new Centrifuge(url,
      {
        getToken: getWSClientToken
      }
    )
    this.socketClient.on('connecting', function (ctx) {
      console.log('connecting', ctx)
    })

    this.socketClient.on('connected', function (ctx) {
      console.log('connected', ctx)
    })

    this.socketClient.on('disconnected', function (ctx) {
      console.log('disconnected', ctx)
    })
    //error您还可以在客户端使用事件工作时侦听内部发生的所有错误（状态更改不会反映这些错误，例如，初始连接时发生的传输错误、重新连接期间的传输、连接令牌刷新相关错误等）
    this.socketClient.on('error', function (ctx) {
      console.log('错误重连')
      console.log(ctx)
      if (ctx.error.code === 1) {
        self.close()
        self.recontact()
      }
      console.log('client error', ctx)
      // token过期会自动重联

    })
    this.socketClient.connect()
    // client.disconnect(); // 断开服务
  },
  // 断线重连
  recontact() {
    console.log('重连')
    const self = this
    this.retryTimer = setTimeout(() => {
      self.contact()
    }, 10000)
  },
  newSubscribe(conversationId) {
    let channel = getChatChannel(conversationId)
    let sub=null
    if(this.channelList&&this.channelList[channel]){
      sub=  this.channelList[channel]
    }else{
      sub = this.socketClient.newSubscription(channel, {
        getToken: subscribeChatChannel
      });
      this.channelList[channel]=sub
    }
    return sub;
  },
  newComAgentSubscribe(comAgentKey,callback){
     getComChatChannel(comAgentKey).then(res=>{
      let sub=null
      let channel=res
      if(this.channelList&&this.channelList[channel]){
        sub=  this.channelList[channel]
      }else{
        sub = this.socketClient.newSubscription(channel, {
          getToken: getSubscribeComChatToken
        });
        this.channelList[channel]=sub
      }
       if (callback && typeof callback === 'function'){
         callback(sub)
       }
    }).catch(err=>{

    })

  }
}

window.addEventListener('beforeunload', () => {
  websocket.close()
})
export default websocket
