import {createRouter, createWebHistory} from 'vue-router'

/* Layout */
import Layout from '@/layout/index'
import userRouter from './modules/pc/user_center'
import chatRouter from './modules/pc/chat'
import agentRouter from './modules/pc/agent'
import imageRouter from './modules/pc/chat'
import knowledgeRouter from './modules/pc/knowledge'
import orgAdminRouter from './modules/org_admin/admin'

// import mobileRouter from './modules/mobile/mobile'

/**
 * 不需要授权
 * constantRoutes
 * a base page that does not have permission requirements
 * all roles can be accessed
 */
export const constantRoutes = [
  {
    path: '/login',
    // component: () => import('@/views/login/index'),
    component: () => import('@/views/login/login'),
    hidden: true
  },
  {
    path: '/test',
    name: 'Test',
    // component: () => import('@/views/login/index'),
    component: () => import('@/views/knowledge/components/doc_pdf_renderer.vue'),
    hidden: true
  },
  {
    name: 'register',
    path: '/register',

    meta: {title: '用户注册'},
    component: () => import('@/views/login/register.vue')
  },
  {
    name: 'forget',
    path: '/forget',

    meta: {title: '忘记密码'},
    component: () => import('@/views/login/forget_the_password.vue')
  },
  {
    path: '/join-team/:invite_code',
    name: 'JoinTeam',
    component: () => import('@/views/user_center/join_team'),
    hidden: true
  },
  {

    path: '/',
    component: Layout,
    redirect: '/dashboard',
    name: 'home',
    meta: {title: 'router.home'},
    children: [
      {
        name: 'Dashboard',
        path: 'dashboard',
        meta: {title: 'router.dashboard'},
        component: () => import('@/views/dashboard.vue')
      },
      {
        name: 'AgentSetting',
        path: 'agent-setting',
        meta: {title: 'router.agent-setting'},
        component: () => import('@/views/agent/agent_setting.vue')
      },
      {
        name: 'user-invitation',
        path: 'invite',
        meta: {title: 'router.invitation'},
        component: () => import('@/views/invitation/invitation.vue')
      },
      {
        name: 'knowledge',
        path: 'knowledge',
        meta: {title: 'router.knowledge'},
        component: () => import('@/views/knowledge/wiki.vue')
      }
    ]
  },
  {
    path: '/chat-share/:share_code',
    name: 'ChatShare',
    component: () => import('@/views/chat/chat_share.vue'),
    hidden: true
  },
  {
    path: '/404',
    name: '404',
    component: () => import('@/views/404'),
    hidden: true
  },
  userRouter,
  chatRouter,
  agentRouter,
  imageRouter,
  orgAdminRouter,
  knowledgeRouter
]

/**
 * 需要授权
 * asyncRoutes
 * the routes that need to be dynamically loaded based on user roles
 */
export const asyncRoutes = []

const router = createRouter({
  history: createWebHistory(),
  routes: constantRoutes
})

export default router
