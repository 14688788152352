import axios from 'axios'

import store from '@/store'
import qs from 'qs'
import router from '@/router'
import {isArray} from '@/utils/validate'
import {ElMessage} from 'element-plus'
import {getLocale} from '@/locales/i18n'
import { notPopUrlList } from '@/settings'
const successCode = [200, 0]
let loadingInstance
let errorStatus = 0

/**
 * @author errorStatus
 * @description 处理code异常
 * @param {*} code
 * @param {*} message
 */
const handleCode = (code, message) => {
  switch (code) {
    case 401:
      if (errorStatus < 1) {
        ElMessage.error(message || '登录失效')
        errorStatus = 1
      }
      store.dispatch('user/resetAll').then(()=>{
        if (router.app.$route.path !== '/login') {
          location.reload() // In order to re-instantiate the vue-router object to avoid bugs
        }
      }).catch(() => {
      })
      break
    case 403:
      router.push({path: '/401'}).then(()=>{}).catch(() => {
      })
      break
    case 1040:
      if (errorStatus !== 2) {
        ElMessage.error(message || '帐号已在其他地方登录！')
        errorStatus = 2
      }
      store.dispatch('user/resetAll').then(()=>{}).catch(() => {
      })
      router.push({path: '/login'}).then(()=>{}).catch(() => {
      })
      break
    default:
      ElMessage.error(message || `后端接口${code}异常`)
      break
  }
}

/**
 * @author AgentStore
 * @description axios初始化
 */
const instance = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 30000,
  headers: {
    'Content-Type': 'application/json;charset=UTF-8',
    'X-Requested-With': 'XMLHttpRequest'
  }
})

/**
 * @author AgentStore
 * @description axios请求拦截器
 */
instance.interceptors.request.use(
  (config) => {
    if (store.getters['user/accessToken']) {
      config.headers['token'] = store.getters['user/accessToken']
    }
    config.headers['Language-Set'] = getLocale()
    if (
      config.data &&
      config.headers['Content-Type'] ===
      'application/x-www-form-urlencoded;charset=UTF-8'
    ) {
      config.data = qs.stringify(config.data)
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

/**
 * @author AgentStore
 * @description axios响应拦截器
 */
instance.interceptors.response.use(
  (response) => {
    if (loadingInstance) loadingInstance.close()

    const {data, config} = response
    const {code, message} = data
    // 操作正常Code数组
    const codeVerificationArray = isArray(successCode)
      ? [...successCode]
      : [...[successCode]]
    // 是否操作正常
    if (codeVerificationArray.includes(code)) {
      errorStatus = 0
      return data
    } else {
      if(config.url&&notPopUrlList.indexOf(config.url)>-1){
        console.log(data)
      }else{
        handleCode(code, message)
      }
      return Promise.reject({url: config.url, code, message, result: false})
    }
  },
  (error) => {
    if (loadingInstance) loadingInstance.close()
    const {response, message} = error
    if (error.response && error.response.data) {
      const {status, data} = response
      handleCode(status, data.message || message)
      return Promise.reject(error)
    } else {
      if(error&&error.config&&error.config.url&&notPopUrlList.indexOf(error.config.url)>-1){
        return Promise.reject(error)
      }else{
        let {message} = error
        if (message === 'Network Error') {
          message = '网络连接失败，请检查您的网络'
        }
        if (message.includes('timeout')) {
          message = '后端接口请求超时'
        }
        if (message.includes('Request failed with status code')) {
          const code = message.substr(message.length - 3)
          message = '后端接口' + code + '异常'
        }
        ElMessage.error(message || `后端接口未知异常`)
        return Promise.reject(error)
      }
    }
  }
)

export default instance
