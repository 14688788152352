const state = () => ({
  agentId: null,
  keyword: ''
})

const getters = {
  agentId: (state) => state.agentId,
  keyword: (state) => state.keyword
}

const mutations = {
  setChatParams(state, params) {
    state.agentId = params.agentId
    state.keyword = params.keyword
  }
}
const actions = {
  async setChatParams({commit}, params) {
    return new Promise((resolve, reject) => {
      commit('setChatParams', params)
      resolve(true)
    })
  },
  async initChatParams({dispatch}) {
    await dispatch('setChatParams', {agentId: null, keyword: ''})
  }
}
export default {namespaced: true, state, getters, mutations, actions}
