/** When your routing table is too long, you can split it into small modules **/

import Layout from '@/layout/index'

const userRouter = {
  path: '/user-center',
  component: Layout,
  redirect: '/user-center/member',
  name: 'UserCenter',
  meta: {
    title: 'router.user-center'
    // roles: ['user']
  },
  children: [
    {
      path: 'user-set',
      component: () => import('@/views/user_center/user_set'), // Parent router-view
      name: 'UserSet',
      meta: {
        title: 'router.user-set'
        // roles: ['user.manage_user']
      }
    },
    {
      path: 'team-invitation',
      component: () => import('@/views/user_center/team_invitation'), // Parent router-view
      name: 'TeamInvitation',
      meta: {
        title: 'router.team-invitation'
        // roles: ['user.manage_user']
      }
    }
  ]
}
export default userRouter
