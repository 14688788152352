import request from '@/utils/request'

/**
 * 获取websocket token
 * */
export function getWSToken(data) {
  return request({
    url: '/api/socket/get-client-token',
    method: 'post',
    data
  })
}

/**
 * 获取Agent Chat 频道订阅token
 * */
export function getWSSubscribeChatToken(data) {
  return request({
    url: '/api/socket/subscribe-chat-token',
    method: 'post',
    data
  })
}

/**
 * 获取Com Agent Chat 频道订阅token
 * */
export function getWSSubscribeComChatToken(data) {
  return request({
    url: '/api/socket/subscribe-com-chat-token',
    method: 'post',
    data
  })
}

/**
 * 获取订阅Com Agent chat 频道的管道
 * */
export function getWSComChatChannel(data) {
  return request({
    url: '/api/socket/get-com-chat-channel',
    method: 'post',
    data
  })
}

