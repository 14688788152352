const getters = {
  token: state => state.user.token,
  avatar: state => state.user.avatar,
  nick_name: state => state.user.nick_name,
  user_name: state => state.user.user_name,
  roles: state => state.user.roles,
  user_id: state => state.user.user_id,
  current_team_id: state => state.user.current_team_id,
  current_team: state => state.user.current_team
}
export default getters
