/** When your routing table is too long, you can split it into small modules **/

import Layout from '@/layout/index'

const knowledgeRouter = {
  path: '/knowledge',
  component: Layout,
  redirect: '/knowledge/wiki',
  name: 'Knowledge',
  meta: {title: 'router.knowledge'},
  children: [
    {
      path: 'wiki',
      component: () => import('@/views/knowledge/wiki.vue'), // Parent router-view
      name: 'Wiki',
      meta: {
        title: 'router.knowledge'
      }
    },
    {
      path: 'knowledge-manage/:uniq_code/:doc_uniq_code?/:share_code?',
      component: () => import('@/views/knowledge/knowledge_manage.vue'), // Parent router-view
      name: 'KnowledgeManage',
      meta: {
        title: 'router.knowledge-manage'
      }
    }
  ]
}
export default knowledgeRouter
