<template>
  <div class="container">
    <el-container>
      <el-aside width="250px">
        <el-scrollbar>
          <div
            v-if="action === 'edit'"
            class="agent-info"
          >
            <el-avatar
              :src="agent.avatar"
              :size="50"
            />
            <div class="detail-info">
              <div class="agent-name">{{ agent.agent_name }}</div>
              <div class="agent-brief">{{ agent.brief }}</div>
            </div>
          </div>

          <el-menu
            active-text-color="#000000"
            text-color="#4e5058"
            :default-active="activeMenu"
          >
            <el-menu-item
              v-for="(item, index) in menuList"
              :key="index"
              :index="item.index"
              @click="changeMenu(item, index)"
            >
              <el-icon>
                <Setting v-if="index === 0" />
                <Connection v-if="index === 1" />
                <DataAnalysis v-if="index === 2 " />
                <headset v-if="index === 3" />
                <UserFilled v-if="index === 4" />
              </el-icon>
              {{ item.title }}
            </el-menu-item>
          </el-menu>
        </el-scrollbar>
      </el-aside>

      <el-container>
        <el-header>
          {{ activeMenuTitle }}
        </el-header>
        <el-main>
          <router-view v-slot="{ Component }">
            <transition
              mode="out-in"
              name="move"
            >
              <component
                :is="Component"
                @changeNav="changeMenu"
              />
            </transition>
          </router-view>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>
import {
  Aim,
  Calendar,
  Connection,
  DataAnalysis, Guide,
  Headset, Place, QuestionFilled,
  Setting,
  UserFilled
} from '@element-plus/icons-vue'

export default {
  name: 'PcAgentIndex',
  components: {
    QuestionFilled, Connection, DataAnalysis, UserFilled, Setting,
    Place, Guide, Aim, Calendar, Headset
  },
  data() {
    return {
      menuList: [
        {index: '1', title: '智能体设置', name: 'AgentCreation', path: '/agent/index/creation'},
        {index: '2', title: '连接集成', name: 'AgentLink', path: '/agent/index/link'},
        {index: '3', title: '数据分析', name: 'AgentDataAnalysis', path: '/agent/index/data-analysis'},
        {index: '4', title: '用户反馈', name: 'AgentUserFeedback', path: '/agent/index/user-feedback'},
        {index: '5', title: '成员设置', name: 'AgentMemberSetting', path: '/agent/index/member-setting'}
      ],
      curPath: '',
      action: 'add',
      activeMenu: '1',
      activeMenuTitle: '智能体设置',
      agent: {}
    }
  },
  computed: {},
  mounted() {
    this.curPath = this.$router.currentRoute.value.path
    const menus = this.menuList.filter(menu => menu.path === this.curPath)
    if (menus && menus.length > 0) {
      this.activeMenu = menus[0].index
    } else {
      this.$router.push({path: '/agent/index/creation'})
    }
  },
  methods: {
    changeMenu(item, index) {
      this.curPath = item.path
      this.activeMenu = item.index
      this.activeMenuTitle = item.title
      this.$router.push({path: item.path})
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  padding: 0;
  width: 100%;
  height: 100vh;

  .el-aside {
    padding: 20px 0;
    color: var(--el-text-color-primary);
    background-color: #e9e9f5;

    .agent-info {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 10px 20px;
      color: #FFFFFF;

      .detail-info {
        padding: 3px 10px;
        display: flex;
        flex-direction: column;

        .agent-name {
          font-size: 16px;
        }

        .agent-brief {
          font-size: 12px;
          color: #afacac;
          max-width: 180px;
          overflow: hidden;
          text-overflow: ellipsis;
          -webkit-line-clamp: 2; /* 行数 */
          /* 如果需要兼容性考虑，还可以添加以下属性 */
          display: -webkit-box;
          -webkit-box-orient: vertical;
        }
      }
    }

    .el-menu {
      background-color: #e9e9f5;

      .el-icon {
        margin-right: 10px;
      }

      .el-menu-item {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px;
        font-size: 15px;

        &:hover {
          background-color: #FFFFFF !important;
        }

        &:active {
          background-color: #FFFFFF !important;
        }
      }

      .el-menu-item.is-active {
        background-color: #FFFFFF;
        font-weight: bold;
      }
    }
  }

  .el-header {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    border-bottom: 1px solid var(--el-border-color);
    font-weight: bold;
    font-size: 18px;
  }

  .el-main {
    padding: 20px;
  }
}
</style>
