module.exports = {
  title: 'AgentStore',
  // 标题分隔符
  titleSeparator: ' - ',
  // 标题是否反转 如果为false:"page - title"，如果为ture:"title - page"
  titleReverse: false,
  routesWhiteList: [
    '/login',
    '/test',
    '/register',
    '/forget',
    '/join-team/:invite_code',
    '/chat-share/:share_code'
  ],
  notPopUrlList: [
    '/api/chat/agent-send-message',
    '/api/agent/counselor-optimize',
    '/api/agent/set-com-agent-chat-status'
  ]
}
