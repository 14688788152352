import request from '@/utils/request'

/**
* 获取多语言资源文件
* */
export function getAppLocale(data) {
  return request({
    url: '/api/i18n/resource-locale',
    method: 'get',
    params: data
  })
}

