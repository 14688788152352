exports.default = {
  mobile_registration: '手机注册',
  mailbox_registration: '邮箱注册',
  please_enter_your_mobile_number: '请输入手机号',
  input_the_phone_number_correctly: '请输入正确手机号',
  please_enter_your_email: '请输入邮箱',
  please_enter_the_verification_code: '请输入验证码',
  password_check_error: '必须包含大小写字母数字特殊字符(~!#$%^&*()_)，至少8位',
  email_check_error: '请输入正确的邮箱',
  please_confirm_the_password_again: '请再次确认密码',
  please_keep_the_same_password_twice: '请保持两次密码一致',
  enter_mobile_number: '输入手机号',
  enter_mail: '输入邮箱',
  verification_code_input: '输入验证码',
  get_verification_code: '获取验证码',
  reading_consent: '阅读同意',
  user_agreement: '《AgentStore服务协议及隐私条款》',
  existing_account_number: '已有账号，',
  log_in_now: '马上登录',
  please_set_the_password: '请设置密码',
  enter_the_password_again: '再次输入密码',
  register: '注册',
  sms_retrieval_password: '短信找回密码',
  mail_retrieval_password: '邮箱找回密码',
  forget_reset: '重置'
}
