exports.default = {
  sms_login: '短信登录',
  pwd_login: '密码登录',
  please_enter_your_mobile_number: '请输入手机号',
  please_enter_your_email: '请输入邮箱',
  please_enter_the_verification_code: '请输入验证码',
  email_check_error: '请输入正确的邮箱',
  verification_code_input: '输入验证码',
  get_verification_code: '获取验证码',
  please_enter_your_mobile_number_or_email: '请输入手机或邮箱',
  please_enter_your_pwd: '请输入密码'
}
