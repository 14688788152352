/** When your routing table is too long, you can split it into small modules **/

import Layout from '@/layout/org_admin_Index'

const orgAdminRouter = {
  name: 'OrgAdmin',
  path: '/org-admin',
  redirect: '/org-admin/index',
  component: Layout,
  meta: { title: '团队后台管理' },
  children: [
    {
      path: 'index',
      name: 'OrgAdminBase',
      meta: { title: 'router.team-set' },
      component: () => import('@/views/org_admin/index.vue')
    },
    {
      path: 'member-department',
      name: 'MemberDepartment',
      meta: { title: 'router.team-member-department' },
      component: () => import('@/views/org_admin/member_department.vue')
    },
    {
      path: 'member-approve',
      name: 'MemberApprove',
      meta: { title: 'router.team-member-approve' },
      component: () => import('@/views/org_admin/member_approve.vue')
    }
  ]
}
export default orgAdminRouter
