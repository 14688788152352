import request from '@/utils/request'

/**
 * 获取用户详情
 * */
export function getUserInfo(data) {
  return request({
    url: '/api/user/account',
    method: 'get',
    params: data
  })
}

/**
* 用户登录
* */
export function login(data) {
  return request({
    url: '/api/user/login',
    method: 'post',
    data
  })
}

/**
* 用户退出登录
* */
export function logout() {
  return request({
    url: '/api/user/logout',
    method: 'post'
  })
}

/**
 * 用户注册
 * */
export function register(data) {
  return request({
    url: '/api/user/register',
    method: 'post',
    data
  })
}

/**
 * 手机注册获取验证码
 * */
export function getRegPhoneCode(data) {
  return request({
    url: '/api/user/get-reg-phone-code',
    method: 'post',
    data
  })
}

/**
 * 邮箱注册获取验证码
 * */
export function getRegEmailCode(data) {
  return request({
    url: '/api/user/get-reg-email-code',
    method: 'post',
    data
  })
}

/**
 * 获取用户中心验证码
 * */
export function getSysSMSCode(data) {
  return request({
    url: '/api/user/get-sys-phone-code',
    method: 'post',
    data
  })
}

/**
 * 获取用户中心验证码
 * */
export function getSysEmailCode(data) {
  return request({
    url: '/api/user/get-sys-email-code',
    method: 'post',
    data
  })
}

/**
 * 重置密码
 * */
export function resetPwdByValidCode(data) {
  return request({
    url: '/api/user/reset-pwd-by-code',
    method: 'post',
    data
  })
}

// 获取重置密码验证码
export function getResetToken(data) {
  return request({
    url: '/api/user/get-reset-token',
    method: 'post',
    data
  })
}

export function getResetPwdByToken(data) {
  return request({
    url: '/api/user/reset-pwd-by-token',
    method: 'post',
    data
  })
}
export function getResetPhoneToken(data) {
  return request({
    url: '/api/user/get-reset-phone-token',
    method: 'post',
    data
  })
}

export function rePhoneBind(data) {
  return request({
    url: '/api/user/get-reset-bind-phone',
    method: 'post',
    data
  })
}

export function updateBaseUserInfo(data) {
  return request({
    url: '/api/user/update-base-user-info',
    method: 'post',
    data
  })
}

/**
* 切换身份
* */
export function switchCurrIdentity(data) {
  return request({
    url: '/api/user/switch-curr-identity',
    method: 'post',
    data
  })
}
