<template>
  <div class="org-admin-layout-container">
    <el-container class="org-admin-container">
      <el-header class="header-bar">
        <div class="header-inner">
          <div class="title-wrap">
            <a
              class="go-home"
              href="/"
            >
              <el-image
                class="logo"
                fit="contain"
                src="/static/images/logo_long.png"
              />
            </a>
            <div class="line" />
            <div class="back-home">
              <a href="/">
                <i
                  class="icon ag-iconfont icon-back"
                />
                <div class="back-text">
                  返回 AgentStore
                </div>
              </a>
            </div>
          </div>
          <div class="options">
            <el-dropdown
              class="team-select-menu"
              trigger="click"
              @command="switchUserCurrIdentity"
            >
              <div class="el-dropdown-link">
                <el-image
                  v-if="currTeamInfo.avatar"
                  fit="cover"
                  class="avatar-img"
                  :src="currTeamInfo.avatar"
                />
                <el-image
                  v-else
                  fit="cover"
                  class="avatar-img"
                  src="/static/images/avatar/user.png"
                />
                {{ currTeamInfo.name }}
                <el-icon class="el-icon--right">
                  <arrow-down />
                </el-icon>
              </div>
              <template #dropdown>
                <el-dropdown-menu class="team-menu-list">
                  <el-dropdown-item
                    v-for="(teamItem,teamItemIndex) in userTeamList"
                    :key="teamItemIndex"
                    :command="teamItem.team_id"
                  >
                    <div class="team-item">
                      <el-image
                        v-if="teamItem.avatar"
                        fit="cover"
                        class="avatar-img"
                        :src="teamItem.avatar"
                      />
                      <el-image
                        v-else
                        fit="cover"
                        class="avatar-img"
                        src="/static/images/avatar/user.png"
                      />
                      <div class="team-name">
                        <div class="">{{ teamItem.name }}</div>
                        <i
                          v-if="$store.getters['user/current_team_id']===teamItem.team_id"
                          class="icon ag-iconfont icon-true"
                        />
                      </div>
                    </div>
                  </el-dropdown-item>
                </el-dropdown-menu>
              </template>
            </el-dropdown>
          </div>
        </div>
      </el-header>
      <el-container class="org-admin-body-container">
        <el-aside
          class="org-admin-layout-aside"
          width="240px"
        >
          <div class="company-menu-box">
            <div
              v-for="(menuItem,menuItemIndex) in menus"
              :key="menuItemIndex"
              class="company-menu-top-item"
            >
              <div class="top-item-text">
                <i
                  v-if="menuItem.icon"
                  class="icon ag-iconfont"
                  :class="'icon-'+menuItem.icon"
                />
                <div
                  class="top-item-title"
                  style="font-weight: bold"
                >
                  {{ menuItem.title }}
                </div>
              </div>
              <div
                v-for="(subMenuItem,subMenuItemIndex) in menuItem.children"
                :key="subMenuItemIndex"
                class="company-menu-sub-item"
                @click="changeNav(subMenuItem)"
              >
                <div
                  class="top-item-text"
                  :class="menuActiveIndex==subMenuItem.code?'active':''"
                >
                  <i
                    v-if="subMenuItem.icon"
                    class="menu-item-icon icon ag-iconfont"
                    :class="'icon-'+subMenuItem.icon"
                  />
                  <div
                    class="top-item-title"
                    :class="subMenuItem.icon?'':'not-icon'"
                  >
                    {{ subMenuItem.title }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!--          <el-menu
            :default-active="menuActiveIndex"
            class="menu-box"
            :collapse="menuCollapse"
            @open="openMenu"
            @close="closeMenu"
          >
            <div
              v-for="(menuItem,menuItemIndex) in menus"
              :key="menuItemIndex"
              class="menu-items-box"
            >
              <el-menu-item
                v-if="(!menuItem.children||menuItem.children.length===0)&&menuItem.show"
                :index="menuItem.code"
                @click="changeNav(menuItem)"
              >
                <span
                  v-if="menuItem.icon"
                  class="menu-icon"
                >
                  <i
                    class="menu-item-icon icon ag-iconfont"
                    :class="'icon-'+menuItem.icon"
                  />
                </span>
                <template #title>
                  <div class="menu-title">{{ menuItem.title }}</div>
                </template>
              </el-menu-item>
              <el-sub-menu
                v-if="menuItem.children&&menuItem.children.length>0&&menuItem.show"
                :index="menuItem.code"
              >
                <template #title>
                  <span
                    v-if="menuItem.icon"
                    class="menu-icon"
                  >
                    <i
                      class="menu-item-icon icon ag-iconfont"
                      :class="'icon-'+menuItem.icon"
                    />
                  </span>
                  <div
                    class="menu-title"
                    :class="menuCollapse?'hide-sub-title':''"
                  >{{ menuItem.title }}
                  </div>
                </template>
                <div
                  v-for="(subMenuItem,subMenuItemIndex) in menuItem.children"
                  :key="subMenuItemIndex"
                  class="sub-menu-box"
                >
                  <el-menu-item
                    v-if="(!subMenuItem.children||subMenuItem.children.length===0)&&subMenuItem.show"
                    :index="subMenuItem.code"
                    @click="changeNav(subMenuItem)"
                  >
                    <span
                      v-if="subMenuItem.icon"
                      class="menu-icon"
                    >
                      <i
                        class="menu-item-icon icon ag-iconfont"
                        :class="'icon-'+subMenuItem.icon"
                      />
                    </span>
                    <template #title>
                      <div class="menu-title">{{ subMenuItem.title }}</div>
                    </template>
                  </el-menu-item>
                  <el-sub-menu
                    v-if="subMenuItem.children&&subMenuItem.children.length>0&&subMenuItem.show"
                    :index="subMenuItem.code"
                  >
                    <template #title>
                      <span
                        v-if="subMenuItem.icon"
                        class="menu-icon"
                      >
                        <i
                          class="menu-item-icon icon ag-iconfont"
                          :class="'icon-'+subMenuItem.icon"
                        />
                      </span>
                      <div class="menu-title">{{ subMenuItem.title }}</div>
                    </template>
                    <el-menu-item
                      v-for="(sSubMenuItem,sSubMenuItemIndex) in subMenuItem.children"
                      :key="sSubMenuItemIndex"
                      :index="sSubMenuItem.code"
                      @click="changeNav(sSubMenuItem)"
                    >
                      <span
                        v-if="sSubMenuItem.icon"
                        class="menu-icon"
                      >
                        <i
                          class="menu-item-icon icon ag-iconfont"
                          :class="'icon-'+sSubMenuItem.icon"
                        />
                      </span>
                      <template #title>
                        <div class="menu-title">{{ sSubMenuItem.title }}</div>
                      </template>
                    </el-menu-item>
                  </el-sub-menu>
                </div>
              </el-sub-menu>
            </div>
          </el-menu>-->
        </el-aside>
        <el-main class="org-admin-layout-main">
          <router-view v-slot="{ Component }">
            <transition
              mode="out-in"
              name="move"
            >
              <component
                :is="Component"
                @changeNav="changeNav"
              />
            </transition>
          </router-view>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>

import { getUserTeams } from '@/api/team'
import { switchCurrIdentity } from '@/api/user'

export default {
  name: 'OrgAdminLayout',
  components: {},
  data() {
    return {
      menus: [
        {
          code: '1', name: 'OrgAdmin', show: true, icon: 'company', title: '团队管理', path: '/org-admin/index',
          children: [
            {
              code: '1_1',
              name: 'OrgAdminBase',
              show: true,
              icon: '',
              title: '团队设置',
              path: '/org-admin/index',
              children: []
            },
            {
              code: '1_2',
              name: 'MemberDepartment',
              show: true,
              icon: '',
              title: '成员部门',
              path: '/org-admin/member-department',
              children: []
            },
            {
              code: '1_3',
              name: 'MemberApprove',
              show: true,
              icon: '',
              title: '成员审批',
              path: '/org-admin/member-approve',
              children: []
            }
          ]
        }
      ],
      curPath: '/org-admin/index',
      menuActiveIndex: '1_1',
      menuCollapse: false,
      userTeamList: [],
      currTeamInfo: {}
    }
  },
  watch: {
    $route(to, from) {
      // this.initMenuActiveIndex(to.name)
    }
  },
  mounted() {
    this.initPageLayout()
    this.getUserTeam()
  },
  methods: {
    async userLogout() {
      await this.$store.dispatch('user/logout')
      this.$router.push('/login')
    },
    openMenu(key, keyPath) {
      // console.log(key, keyPath)
    },
    closeMenu(key, keyPath) {
      // console.log(key, keyPath)
    },
    changeNav(item) {
      this.curPath = item.path
      this.menuActiveIndex = item.code
      this.$router.push({name: item.name})
    },
    getUserTeam() {
      this.userTeamList = []
      getUserTeams({}).then(response => {
        if (response && response.code === 0) {
          for (let i = 0; i < response.data.length; i++) {
            const item = response.data[i]
            if (item.team_type < 1 && item['team_roles'] && item['team_roles'].indexOf('team_manage') > -1) {
              this.userTeamList.push(item)
            }
            // console.log(this.$store.getters['user/current_team_id'])
            if (item.team_id === this.$store.getters['user/current_team_id']) {
              this.currTeamInfo = item
              if (this.currTeamInfo.team_type > 0) {
                this.$router.push('/')
              }
            }
          }
          if (!this.currTeamInfo || !this.currTeamInfo.team_id || !this.currTeamInfo['team_roles'] ||
              this.currTeamInfo['team_roles'].indexOf('team_manage') < 0) {
            this.$message.error('你没有管理权限！')
            this.$router.push('/')
          }
        }
      }).catch(() => {

      })
    },
    switchUserCurrIdentity(teamId) {
      if (this.$store.getters['user/current_team_id'] !== teamId) {
        switchCurrIdentity({current_team_id: teamId}).then(response => {
          if (response && response.code === 0) {
            // 切换身份刷新页面获取数据
            window.location.reload()
          }
        }).catch(() => {

        })
      } else {
        this.$refs['userBarPopover'].hide()
      }
    },
    initMenuActiveIndex(pathName) {
      this.menuActiveIndex = this.getCode(this.menus, pathName)
      console.log(this.menuActiveIndex)
    },
    initPageLayout(pathName) {
      if (!pathName) {
        pathName = this.$route.name
      }
      this.initMenuActiveIndex(pathName)
    },
    getCode(menu, pathName) {
      let code = ''
      for (let i = 0; i < menu.length; i++) {
        if (typeof menu[i].children === 'undefined' || menu[i].children.length < 1) {
          if (menu[i].name.indexOf(pathName) > -1) {
            code = menu[i].code
            break
          }
        } else {
          code = this.getCode(menu[i].children, pathName)
          if (code !== '') {
            break
          }
        }
      }
      return code
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/assets/iconfont/iconfont.css';

.org-admin-layout-container {
  position: absolute;
  height: 100%;
  width: 100%;
  .org-admin-container {
    position: relative;
    height: 100%;
    .header-bar {
      z-index: 10;
      width: 100%;
      height: 50px;
      background: #fff;
      box-shadow: 0 4px 8px rgba(29, 41, 57, .1);

      .header-inner {
        height: 100%;
        display: flex;
        margin: 0 auto;
        padding: 0 20px 0 8px;
        align-items: center;
        justify-content: space-between;

        .title-wrap {
          display: flex;
          align-items: center;

          .go-home {
            background-color: transparent;
            text-decoration: none;

            .logo {
              width: 24px;
              height: 24px;
              margin-left: 8px;
              cursor: pointer;
            }
          }

          .line {
            margin: 0 16px;
            width: 1px;
            height: 24px;
            background-color: #eeeff1;
          }
          .back-home{
            display: flex;
            flex-direction: row;
            align-content: center;
            align-items: center;
            height: 56px;
            margin: 0 20px;
            font-size: 14px;
            a{
              font-size: 14px;
              display: flex;
              flex-direction: row;
              align-content: center;
              align-items: center;
              height: 100%;
              color: #606266 !important;
              .iconfont{
                padding-right: 10px;
              }
            }
          }
        }

        .options {
          display: flex;
          align-items: center;
          .team-select-menu{
            .el-dropdown-link{
              display: flex;
              flex-direction: row;
              align-content: center;
              flex-wrap: nowrap;
              align-items: center;
              cursor: pointer;
              .avatar-img{
                background: #FFFFFF;
                border-radius: 50%;
                width: 25px;
                height: 25px;
                opacity: 1;
                overflow: hidden;
                position: relative;
                margin-right: 10px;

                img {
                  height: 100%;
                  width: 100%;
                  object-fit: cover;
                  vertical-align: top;
                }
              }
            }
          }
        }
      }
    }
    .org-admin-body-container{
      position: relative;
      height: calc(100% - 50px);
      .org-admin-layout-aside{
        overflow-y: auto;
        background-color: #f9fafb;
        .company-menu-box{
          font-size: 14px;
          .company-menu-top-item{
            display: flex;
            flex-direction: column;
            .top-item-text{
              height: 56px;
              display: flex;
              flex-direction: row;
              flex-wrap: nowrap;
              align-content: center;
              align-items: center;
              margin: 0 20px;
              cursor: default;
              .iconfont{
                padding-right: 10px;
              }
              .not-icon{
                margin-left: 26px;
              }
            }
            .active{
              color: #409eff;
            }

            .company-menu-sub-item{
              .top-item-text{
                height: 56px;
                cursor: pointer;
              }
              .active{
                color: #409eff;
              }
            }
          }
        }
        .menu-box {
          border-right: none;
          .menu-items-box {
            .menu-item-icon {
              font-size: 20px;
            }
            .menu-icon {
              width: 22px;
            }
            .hide-sub-title {
              display: none;
            }
            ::v-deep(.el-sub-menu__icon-arrow) {
              right: 10px;
            }
            .menu-title {
              padding-left: 25px;
            }
            ::v-deep(.el-menu-item) {
              &:hover {
                background: rgba(255, 255, 255, .16);
              }
            }
            ::v-deep(.el-sub-menu) {
              .el-sub-menu__title {
                &:hover {
                  background: rgba(255, 255, 255, .16);
                }
              }
            }
          }
        }
      }
      .org-admin-layout-main{

      }
    }
  }
}
.team-menu-list{
  .team-item{
    display: flex;
    flex-direction: row;
    align-content: center;
    align-items: center;
    flex-wrap: nowrap;
    width: 100%;
    .avatar-img{
      background: #FFFFFF;
      border-radius: 50%;
      width: 25px;
      height: 25px;
      opacity: 1;
      overflow: hidden;
      position: relative;
      margin-right: 10px;

      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        vertical-align: top;
      }
    }
    .team-name{
      display: flex;
      flex-direction: row;
      align-content: center;
      align-items: center;
      justify-content: space-between;
      flex-wrap: nowrap;
      width: 100%;
      .icon-true {
        color: #1b9aee;
      }
    }
  }
}
.user-opt-box {
  .user-manage-menu {
    border: none;

    .user-manage-menu-item {
      height: 50px;

      &:hover {
        background-color: #f7f7f7;
      }

      .user-set {
        .icon {
          font-size: 24px;
        }

        span {
          padding-left: 10px;
        }
      }

      .quit {
        color: #e62412;

        .icon {
          font-size: 24px;
        }

        span {
          padding-left: 10px;
        }
      }
    }
  }
}

</style>
