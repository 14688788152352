// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("iconfont.woff2?t=1723628130972", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("iconfont.woff?t=1723628130972", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("iconfont.ttf?t=1723628130972", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face {\n  font-family: \"ag-iconfont\"; /* Project id 4349880 */\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format('woff2'),\n       url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format('woff'),\n       url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") format('truetype');\n}\n\n.ag-iconfont {\n  font-family: \"ag-iconfont\" !important;\n  font-size: 16px;\n  font-style: normal;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n}\n\n.icon-read:before {\n  content: \"\\e645\";\n}\n\n.icon-ag_close:before {\n  content: \"\\e646\";\n}\n\n.icon-ag_download:before {\n  content: \"\\e644\";\n}\n\n.icon-left-bar:before {\n  content: \"\\e643\";\n}\n\n.icon-knowledge:before {\n  content: \"\\e642\";\n}\n\n.icon-expand:before {\n  content: \"\\e640\";\n}\n\n.icon-shrink:before {\n  content: \"\\e641\";\n}\n\n.icon-more:before {\n  content: \"\\e63f\";\n}\n\n.icon-team_member:before {\n  content: \"\\e63e\";\n}\n\n.icon-company:before {\n  content: \"\\e63d\";\n}\n\n.icon-back:before {\n  content: \"\\e63c\";\n}\n\n.icon-exit:before {\n  content: \"\\e63b\";\n}\n\n.icon-set:before {\n  content: \"\\e638\";\n}\n\n.icon-users:before {\n  content: \"\\e639\";\n}\n\n.icon-user-add:before {\n  content: \"\\e63a\";\n}\n\n.icon-wechat:before {\n  content: \"\\e636\";\n}\n\n.icon-chat:before {\n  content: \"\\e637\";\n}\n\n.icon-plus:before {\n  content: \"\\e635\";\n}\n\n.icon-true:before {\n  content: \"\\e634\";\n}\n\n.icon-database:before {\n  content: \"\\e62d\";\n}\n\n.icon-gpt:before {\n  content: \"\\e62e\";\n}\n\n.icon-user:before {\n  content: \"\\e630\";\n}\n\n.icon-home:before {\n  content: \"\\e631\";\n}\n\n.icon-menu:before {\n  content: \"\\e632\";\n}\n\n.icon-user_b:before {\n  content: \"\\e633\";\n}\n\n", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
