import request from '@/utils/request'

/**
 * 获取行业树
 * */
export function getTreeIndustry(data) {
  return request({
    url: '/api/dict/get-tree-industry',
    method: 'get',
    params: data
  })
}
