import { getSite } from '@/utils/common'
import cookie from 'js-cookie'
import { getCookieKeyPrefix } from './common'
// token存储位置localStorage sessionStorage cookie
const storage = 'cookie'
const tokenTableName = 'Agent_Store_43EB73463FC9DB'
/**
 * @author AgentStore
 * @description 获取accessToken
 * @returns {string|ActiveX.IXMLDOMNode|Promise<any>|any|IDBRequest<any>|MediaKeyStatus|FormDataEntryValue|Function|Promise<Credential | null>}
 */
export function getAccessToken() {
  if (storage) {
    if (storage === 'localStorage') {
      return localStorage.getItem(tokenTableName)
    } else if (storage === 'sessionStorage') {
      return sessionStorage.getItem(tokenTableName)
    } else if (storage === 'cookie') {
      const accessToken = cookie.get(getCookieKeyPrefix() + '_' + tokenTableName)
      return accessToken
    } else {
      return localStorage.getItem(tokenTableName)
    }
  } else {
    return localStorage.getItem(tokenTableName)
  }
}

/**
 * @author AgentStore
 * @description 存储accessToken
 * @param accessToken
 * @returns {void|*}
 */
export function setAccessToken(accessToken) {
  if (storage) {
    if (storage === 'localStorage') {
      return localStorage.setItem(tokenTableName, accessToken)
    } else if (storage === 'sessionStorage') {
      return sessionStorage.setItem(tokenTableName, accessToken)
    } else if (storage === 'cookie') {
      return cookie.set(getCookieKeyPrefix() + '_' + tokenTableName, accessToken,{expires:15}) // 15天过期世家
    } else {
      return localStorage.setItem(tokenTableName, accessToken)
    }
  } else {
    return localStorage.setItem(tokenTableName, accessToken)
  }
}

/**
 * @author AgentStore
 * @description 移除accessToken
 * @returns {void|Promise<void>}
 */
export function removeAccessToken() {
  if (storage) {
    if (storage === 'localStorage') {
      return localStorage.removeItem(tokenTableName)
    } else if (storage === 'sessionStorage') {
      return sessionStorage.clear()
    } else if (storage === 'cookie') {
      return cookie.remove(getSite() + '_' + tokenTableName)
    } else {
      return localStorage.removeItem(tokenTableName)
    }
  } else {
    return localStorage.removeItem(tokenTableName)
  }
}
