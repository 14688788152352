<template>
  <div class="code-join-team-container">
    <div class="code-join-team-box">
      <el-form
        ref="teamJoinForm"
        v-loading="saveLoading"
        :model="teamJoinForm"
        :rules="teamJoinRules"
        class="code-join-team-form"
        label-width="120px"
      >
        <el-form-item
          label="邀请码"
          prop="inviteCode"
        >
          <el-input
            v-model="teamJoinForm.inviteCode"
            placeholder="请填写邀请码"
          />
        </el-form-item>
      </el-form>
    </div>

  </div>
</template>

<script>
import { getTeamInfoByInviteCode, joinTeam } from '@/api/team'

export default {
  name: 'CodeJoinTeam',
  components: {},
  props: {
    isDialog: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      teamJoinForm: {
        inviteCode: ''
      },
      teamJoinRules: {
        inviteCode: [
          { required: true, message: '请填写邀请码', trigger: 'blur' }
        ]
      },
      saveLoading: false,
      teamInfo: {}
    }
  },
  mounted() {
  },
  methods: {
    joinTeam() {
      this.$refs['teamJoinForm'].validate((valid) => {
        if (valid) {
          const params = {}
          if (this.saveLoading) {
            return false
          }
          this.saveLoading = true
          params.invite_code = this.teamJoinForm.inviteCode
          this.getTeamInfo().then(res => {
            if (res) {
              joinTeam(params).then((response) => {
                this.saveLoading = false
                if (response && response.code === 0) {
                  if (this.isDialog) {
                    if (this.teamInfo && this.teamInfo.apply_audit) {
                      this.$message.success('您已成功申请加入团队！等待管理员审核后将加入团队')
                      this.$emit('close-dlg')
                    } else {
                      this.$message.success('您已成功加入团队！')
                      this.$emit('update-team', response.data)
                    }
                  }
                }
              }).catch(() => {
                this.saveLoading = false
              })
            }
          }).catch(e => {

          })
        } else {
          return false
        }
      })
    },
    async getTeamInfo() {
      return new Promise((resolve, reject) => {
        getTeamInfoByInviteCode({ invite_code: this.teamJoinForm.inviteCode }).then((response) => {
          if (response && response.code === 0) {
            this.teamInfo = response.data
          }
          resolve(true)
        }).catch(() => {
          resolve(false)
        })
      })
    },
    initJoinTeam() {
      this.teamJoinForm.inviteCode = ''
    }
  }
}
</script>

<style lang="scss" scoped>
.code-join-team-container {
  .code-join-team-box {
    .code-join-team-form {

      .team-scale {
        width: 100%;
      }
    }
  }

}
</style>
