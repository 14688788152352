/**
 * @author AgentStore
 * @description 格式化时间
 * @param time
 * @param cFormat
 * @returns {string|null}
 */
export function parseTime(time, cFormat) {
  if (arguments.length === 0) {
    return null
  }
  const format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}'
  let date
  if (typeof time === 'object') {
    date = time
  } else {
    if (typeof time === 'string' && /^[0-9]+$/.test(time)) {
      time = parseInt(time)
    }
    if (typeof time === 'number' && time.toString().length === 10) {
      time = time * 1000
    }
    date = new Date(time)
  }
  const formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay()
  }
  return format.replace(/{([ymdhisa])+}/g, (result, key) => {
    let value = formatObj[key]
    if (key === 'a') {
      return ['日', '一', '二', '三', '四', '五', '六'][value]
    }
    if (result.length > 0 && value < 10) {
      value = '0' + value
    }
    return value || 0
  })
}

/**
 * @author AgentStore
 * @description 格式化时间
 * @param time
 * @param option
 * @returns {string}
 */
export function formatTime(time, option) {
  if (('' + time).length === 10) {
    time = parseInt(time) * 1000
  } else {
    time = '' + time
  }
  const d = new Date(time)
  const now = Date.now()

  const diff = (now - d) / 1000

  if (diff < 30) {
    return '刚刚'
  } else if (diff < 3600) {
    // scss 1 hour
    return Math.ceil(diff / 60) + '分钟前'
  } else if (diff < 3600 * 24) {
    return Math.ceil(diff / 3600) + '小时前'
  } else if (diff < 3600 * 24 * 2) {
    return '昨天'
  }
  if (option) {
    return parseTime(time, option)
  } else {
    return (
      d.getMonth() +
      1 +
      '月' +
      d.getDate() +
      '日'
    )
  }
}
export function deepClone(target, result) {
  // 定义一个变量
  result = result || {}

  // 如果当前需要深拷贝的是一个对象的话
  if (typeof target === 'object') {
    // 如果是一个数组的话
    if (Array.isArray(target)) {
      if (Array.isArray(result)) {
        result.splice(0)
      } else {
        result = [] // 将result赋值为一个数组，并且执行遍历
      }
      for (const i in target) {
        // 递归克隆数组中的每一项
        result.push(deepClone(target[i]))
      }
      // 判断如果当前的值是null的话；直接赋值为null
    } else if (target === null) {
      result = null
      // 判断如果当前的值是一个RegExp对象的话，直接赋值
    } else if (target.constructor === RegExp) {
      result = target
    } else {
      // 否则是普通对象，直接for in循环，递归赋值对象的所有值
      // result = {};
      for (const i in target) {
        result[i] = deepClone(target[i])
      }
    }
    // 如果不是对象的话，就是基本数据类型，那么直接赋值
  } else {
    result = target
  }
  // 返回最终结果
  return result
}
/**
 * @author AgentStore
 * @description 将url请求参数转为json格式
 * @param url
 * @returns {{}|any}
 */
export function paramObj(url) {
  const search = url.split('?')[1]
  if (!search) {
    return {}
  }
  return JSON.parse(
    '{"' +
      decodeURIComponent(search)
        .replace(/"/g, '\\"')
        .replace(/&/g, '","')
        .replace(/=/g, '":"')
        .replace(/\+/g, ' ') +
      '"}'
  )
}

/**
 * @author AgentStore
 * @description 父子关系的数组转换成树形结构数据
 * @param data
 * @returns {*}
 */
export function translateDataToTree(data) {
  const parent = data.filter(
    (value) => value.parentId === 'undefined' || value.parentId == null
  )
  const children = data.filter(
    (value) => value.parentId !== 'undefined' && value.parentId != null
  )
  const translator = (parent, children) => {
    parent.forEach((parent) => {
      children.forEach((current, index) => {
        if (current.parentId === parent.id) {
          const temp = JSON.parse(JSON.stringify(children))
          temp.splice(index, 1)
          translator([current], temp)
          typeof parent.children !== 'undefined'
            ? parent.children.push(current)
            : (parent.children = [current])
        }
      })
    })
  }
  translator(parent, children)
  return parent
}

/**
 * @author AgentStore
 * @description 树形结构数据转换成父子关系的数组
 * @param data
 * @returns {[]}
 */
export function translateTreeToData(data) {
  const result = []
  data.forEach((item) => {
    const loop = (data) => {
      result.push({
        id: data.id,
        name: data.name,
        parentId: data.parentId
      })
      const child = data.children
      if (child) {
        for (let i = 0; i < child.length; i++) {
          loop(child[i])
        }
      }
    }
    loop(item)
  })
  return result
}

/**
 * @author AgentStore
 * @description 10位时间戳转换
 * @param time
 * @returns {string}
 */
export function tenBitTimestamp(time) {
  const date = new Date(time * 1000)
  const y = date.getFullYear()
  let m = date.getMonth() + 1
  m = m < 10 ? '' + m : m
  let d = date.getDate()
  d = d < 10 ? '' + d : d
  let h = date.getHours()
  h = h < 10 ? '0' + h : h
  let minute = date.getMinutes()
  let second = date.getSeconds()
  minute = minute < 10 ? '0' + minute : minute
  second = second < 10 ? '0' + second : second
  return y + '年' + m + '月' + d + '日 ' + h + ':' + minute + ':' + second // 组合
}

/**
 * @author AgentStore
 * @description 13位时间戳转换
 * @param time
 * @returns {string}
 */
export function thirteenBitTimestamp(time) {
  const date = new Date(time / 1)
  const y = date.getFullYear()
  let m = date.getMonth() + 1
  m = m < 10 ? '' + m : m
  let d = date.getDate()
  d = d < 10 ? '' + d : d
  let h = date.getHours()
  h = h < 10 ? '0' + h : h
  let minute = date.getMinutes()
  let second = date.getSeconds()
  minute = minute < 10 ? '0' + minute : minute
  second = second < 10 ? '0' + second : second
  return y + '年' + m + '月' + d + '日 ' + h + ':' + minute + ':' + second // 组合
}

/**
 * @author AgentStore
 * @description 获取随机id
 * @param length
 * @returns {string}
 */
export function uuid(length = 32) {
  const num = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890'
  let str = ''
  for (let i = 0; i < length; i++) {
    str += num.charAt(Math.floor(Math.random() * num.length))
  }
  return str
}

/**
 * @author AgentStore
 * @description m到n的随机数
 * @param m
 * @param n
 * @returns {number}
 */
export function random(m, n) {
  return Math.floor(Math.random() * (m - n) + n)
}

/**
 * @author AgentStore
 * @description addEventListener
 * @type {function(...[*]=)}
 */
export const on = (function() {
  return function(element, event, handler, useCapture = false) {
    if (element && event && handler) {
      element.addEventListener(event, handler, useCapture)
    }
  }
})()
/**
 * 是否移动设备类型
 * */
export const isMobileType = {
  Android: function() {
    return navigator.userAgent.match(/Android/i)
  },
  BlackBerry: function() {
    return navigator.userAgent.match(/BlackBerry|BB10/i)
  },
  iOS: function() {
    return navigator.userAgent.match(/iPhone|iPad|iPod/i)
  },
  Opera: function() {
    return navigator.userAgent.match(/Opera Mini/i)
  },
  Windows: function() {
    return navigator.userAgent.match(/IEMobile/i)
  },
  isSafari: function() {
    return /Safari/.test(navigator.userAgent) && !/Chrome/.test(navigator.userAgent)
  },
  any: function() {
    return (isMobileType.Android() || isMobileType.BlackBerry() || isMobileType.iOS() || isMobileType.Opera() || isMobileType.Windows())
  },
  getOsName: function() {
    var osName = 'Unknown OS'
    if (isMobileType.Android()) {
      osName = 'Android'
    }

    if (isMobileType.BlackBerry()) {
      osName = 'BlackBerry'
    }

    if (isMobileType.iOS()) {
      osName = 'iOS'
    }

    if (isMobileType.Opera()) {
      osName = 'Opera Mini'
    }

    if (isMobileType.Windows()) {
      osName = 'Windows'
    }
    return {
      osName,
      type: 'mobile'
    }
  }
}

/**
 * 查看桌面系统
 * */
export function detectDesktopOS() {
  var unknown = '-'

  var nVer = navigator.appVersion
  var nAgt = navigator.userAgent

  var os = unknown
  var clientStrings = [{
    s: 'Chrome OS',
    r: /CrOS/
  }, {
    s: 'Windows 10',
    r: /(Windows 10.0|Windows NT 10.0)/
  }, {
    s: 'Windows 8.1',
    r: /(Windows 8.1|Windows NT 6.3)/
  }, {
    s: 'Windows 8',
    r: /(Windows 8|Windows NT 6.2)/
  }, {
    s: 'Windows 7',
    r: /(Windows 7|Windows NT 6.1)/
  }, {
    s: 'Windows Vista',
    r: /Windows NT 6.0/
  }, {
    s: 'Windows Server 2003',
    r: /Windows NT 5.2/
  }, {
    s: 'Windows XP',
    r: /(Windows NT 5.1|Windows XP)/
  }, {
    s: 'Windows 2000',
    r: /(Windows NT 5.0|Windows 2000)/
  }, {
    s: 'Windows ME',
    r: /(Win 9x 4.90|Windows ME)/
  }, {
    s: 'Windows 98',
    r: /(Windows 98|Win98)/
  }, {
    s: 'Windows 95',
    r: /(Windows 95|Win95|Windows_95)/
  }, {
    s: 'Windows NT 4.0',
    r: /(Windows NT 4.0|WinNT4.0|WinNT|Windows NT)/
  }, {
    s: 'Windows CE',
    r: /Windows CE/
  }, {
    s: 'Windows 3.11',
    r: /Win16/
  }, {
    s: 'Android',
    r: /Android/
  }, {
    s: 'Open BSD',
    r: /OpenBSD/
  }, {
    s: 'Sun OS',
    r: /SunOS/
  }, {
    s: 'Linux',
    r: /(Linux|X11)/
  }, {
    s: 'iOS',
    r: /(iPhone|iPad|iPod)/
  }, {
    s: 'Mac OS X',
    r: /Mac OS X/
  }, {
    s: 'Mac OS',
    r: /(MacPPC|MacIntel|Mac_PowerPC|Macintosh)/
  }, {
    s: 'QNX',
    r: /QNX/
  }, {
    s: 'UNIX',
    r: /UNIX/
  }, {
    s: 'BeOS',
    r: /BeOS/
  }, {
    s: 'OS/2',
    r: /OS\/2/
  }, {
    s: 'Search Bot',
    r: /(nuhk|Googlebot|Yammybot|Openbot|Slurp|MSNBot|Ask Jeeves\/Teoma|ia_archiver)/
  }]
  for (var i = 0, cs; cs = clientStrings[i]; i++) {
    if (cs.r.test(nAgt)) {
      os = cs.s
      break
    }
  }

  var osVersion = unknown

  if (/Windows/.test(os)) {
    if (/Windows (.*)/.test(os)) {
      osVersion = /Windows (.*)/.exec(os)[1]
    }
    os = 'Windows'
  }

  switch (os) {
    case 'Mac OS X':
      if (/Mac OS X (10[\.\_\d]+)/.test(nAgt)) {
        osVersion = /Mac OS X (10[\.\_\d]+)/.exec(nAgt)[1]
      }
      break
    case 'Android':
      if (/Android ([\.\_\d]+)/.test(nAgt)) {
        osVersion = /Android ([\.\_\d]+)/.exec(nAgt)[1]
      }
      break
    case 'iOS':
      if (/OS (\d+)_(\d+)_?(\d+)?/.test(nAgt)) {
        osVersion = /OS (\d+)_(\d+)_?(\d+)?/.exec(nVer)
        osVersion = osVersion[1] + '.' + osVersion[2] + '.' + (osVersion[3] | 0)
      }
      break
  }

  return {
    osName: os + osVersion,
    type: 'desktop'
  }
}

/**
 * 获取操作系统
 * */
export function getOS() {
  if (isMobileType.any()) {
    return isMobileType.getOsName()
  } else {
    return detectDesktopOS()
  }
}
/**
 * 获取浏览器
 * */
export function getBrowser() {
  var sys = {}
  var ua = navigator.userAgent.toLowerCase()
  var s;
  (s = ua.match(/edge\/([\d.]+)/))
    ? (sys.edge = s[1])
    : (s = ua.match(/rv:([\d.]+)\) like gecko/))
      ? (sys.ie = s[1])
      : (s = ua.match(/msie ([\d.]+)/))
        ? (sys.ie = s[1])
        : (s = ua.match(/firefox\/([\d.]+)/))
          ? (sys.firefox = s[1])
          : (s = ua.match(/chrome\/([\d.]+)/))
            ? (sys.chrome = s[1])
            : (s = ua.match(/opera.([\d.]+)/))
              ? (sys.opera = s[1])
              : (s = ua.match(/version\/([\d.]+).*safari/))
                ? (sys.safari = s[1])
                : 0

  if (sys.edge) return { broswer: 'Edge', version: sys.edge }
  if (sys.ie) return { broswer: 'IE', version: sys.ie }
  if (sys.firefox) return { broswer: 'Firefox', version: sys.firefox }
  if (sys.chrome) return { broswer: 'Chrome', version: sys.chrome }
  if (sys.opera) return { broswer: 'Opera', version: sys.opera }
  if (sys.safari) return { broswer: 'Safari', version: sys.safari }

  return { broswer: '', version: '0' }
}
/**
 * @author AgentStore
 * @description removeEventListener
 * @type {function(...[*]=)}
 */
export const off = (function() {
  return function(element, event, handler, useCapture = false) {
    if (element && event) {
      element.removeEventListener(event, handler, useCapture)
    }
  }
})()

/**
 * 从 window.location.href 中获取指定key的value
 * @param {*} key 要获取的 key
 * @returns window.location.href 中指定key对应的value
 * @example
 * const value = getUrlParam(key);
 */
export function getUrlParam(key) {
  const url = window.location.href.replace(/^[^?]*\?/, '')
  const regexp = new RegExp(`(^|&)${key}=([^&#]*)(&|$|)`, 'i')
  const paramMatch = url.match(regexp)

  return paramMatch ? paramMatch[2] : null
}

export function httpBuildQuery(params){
  return Object.keys(params).map((key) => {
    if (Array.isArray(params[key])) {
      return params[key].map(value => `${encodeURIComponent(key)}[]=${encodeURIComponent(value)}`).join('&')
    }
    return `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`
  }).join('&')
}

/**
* 计算字符串长度
* */
export function strLen(str) {
  if (typeof str === 'string') {
    return str.replace(/[^\x00-\xff]/g, '01').length
  }else{
    return 0
  }
}
/**
* 字符串截取支持中文截取
* */
export function  cutByte(str, len, endstr) {
  var len = +len,
    endstr = typeof (endstr) === 'undefined' ? '' : endstr.toString(),
    endstrBl = this.strLen(endstr)
  function n2(a) { var n = a / 2 | 0; return (n > 0 ? n : 1) }// 用于二分法查找
  if (!(str + '').length || !len || len <= 0) {
    return ''
  }
  if (len < endstrBl) {
    endstr = ''
    endstrBl = 0
  }
  var lenS = len - endstrBl,
    _lenS = 0,
    _strl = 0
  while (_strl <= lenS) {
    var _lenS1 = n2(lenS - _strl),
      addn = this.strLen(str.substr(_lenS, _lenS1))
    if (addn == 0) { return str }
    _strl += addn
    _lenS += _lenS1
  }
  if (str.length - _lenS > endstrBl || this.strLen(str.substring(_lenS - 1)) > endstrBl) {
    return str.substr(0, _lenS - 1) + endstr
  } else {
    return str
  }
}

/**
 * 通用名称构造方案
 * 仅支持大小写字母，数字、下划线、破折号、空格、小数点、中文，将不符合的字符默认替换为-
 * 中间多个空格替换为 一个空格
 * */
export function commonNameBuild(str) {
  const nameRegex = /[^\u4e00-\u9fa5a-zA-Z0-9_\-\s.]+/g;
  const duplicateDashRegex = /-+/g;
  const duplicateSpaceRegex = /\s+/g;
  if (typeof str === 'string') {
    let sanitizedName = str.replace(nameRegex, '-');
    sanitizedName = sanitizedName.replace(duplicateDashRegex, '-');
    sanitizedName = sanitizedName.replace(duplicateSpaceRegex, ' ');
    return sanitizedName
  }else{
    return ''
  }
}

export function download(url) {
  let iframe = document.createElement('iframe')
  if(url.indexOf('?')>-1){
    iframe.src = url
  }else{
    iframe.src = url + '?response-content-type=application%2Foctet-stream'
  }
  iframe.style.display = 'none'
  document.body.append(iframe)
}