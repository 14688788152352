<template>
  <div id="agent_store">
    <el-config-provider :locale="nowLocale">
      <router-view />
    </el-config-provider>
  </div>

</template>

<script>
import i18n from '@/locales/i18n'
import zhLocale from 'element-plus/es/locale/lang/zh-cn'
import twLocale from 'element-plus/es/locale/lang/zh-tw'
import enLocale from 'element-plus/es/locale/lang/en'
// import store from '@/store'
// import { mapActions } from 'vuex'
// import { getDefLanguage } from '@/utils/common'
const debounce = (fn, delay) => {
  let timer = null
  return function () {
    const context = this
    const args = arguments
    clearTimeout(timer)
    timer = setTimeout(function () {
      fn.apply(context, args)
    }, delay)
  }
}

const _ResizeObserver = window.ResizeObserver
window.ResizeObserver = class ResizeObserver extends _ResizeObserver {
  constructor(callback) {
    callback = debounce(callback, 16)
    super(callback)
  }
}

export default {
  name: 'App',
  data() {
    return {
      localeList: {
        'en-us': enLocale,
        'zh-cn': zhLocale,
        'zh-tw': twLocale
      }
    }
  },
  computed: {
    nowLocale() {
      // const language = getDefLanguage()
      // this.setLanguageFormat(language)
      return this.localeList[i18n.global.locale.value]
    }
  },
  created() {
    if (this.$store.getters['user/accessToken']) {
      this.$websocket.init(this)
    }
  },
  methods: {
    // ...mapActions({
    //   setLanguageFormat: 'settings/setLanguageFormat'
    // })
  }
}
</script>

<style lang="scss">
html, body {
  height: 100%;
  margin: 0;
  padding: 0;
}

#app {
  margin: 0 !important;
  padding: 0 !important;
  font-family: Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB, Microsoft YaHei, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}

.el-overlay-dialog {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  .el-dialog {
    margin: 0;

    .el-dialog__body {
      max-height: 90vh
    }
  }
}
</style>
