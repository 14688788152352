import { createI18n } from 'vue-i18n'
import { nextTick } from 'vue'
import { getLanguage, getCookieKeyPrefix } from '@/utils/common'
import { getUrlParam } from '@/utils/index'
import { getAppLocale } from '@/api'
import Cookies from 'js-cookie'

const globalLocaleCookieKey = getCookieKeyPrefix() + '_ASGlobalLocale_FF117B9D62'
const localeCookieKey = getCookieKeyPrefix() + '_AsLocalLocale_FF117B9D62'

export function getLocale() {
  let locale = null
  if (document.domain !== 'localhost') {
    locale = Cookies.get(globalLocaleCookieKey)
  } else {
    locale = Cookies.get(localeCookieKey)
  }
  return (
    getUrlParam('lang') ||
    locale ||
    getLanguage()
  )
}
function loadLocaleMessages() {
  const locales = require.context('./lang', true, /[A-Za-z0-9-_,\s]+\.js$/i)
  const messages = { 'zh-cn': {}}
  locales.keys().forEach((key) => {
    if (key.indexOf('/module/') === -1) {
      const matched = key.match(/([A-Za-z0-9-_]+)\./i)
      if (matched && matched.length > 1) {
        const locale = matched[1]
        messages[locale] = locales(key)['default']
      }
    }
  })
  locales.keys().forEach((key) => {
    if (key.indexOf('/module/') !== -1) {
      const matched = key.match(/([^/.]+)\./i)
      if (matched && matched.length > 1) {
        messages['zh-cn'][matched[1]] = locales(key)['default']
      }
    }
  })
  return messages
}

export function setI18nLanguage(i18n, locale) {
  if (i18n.mode === 'legacy') {
    i18n.global.locale = locale
  } else {
    i18n.global.locale.value = locale
  }
  /**
   * NOTE:
   * If you need to specify the language setting for headers, such as the `fetch` API, set it here.
   * The following is an example for axios.
   *
   * axios.defaults.headers.common['Accept-Language'] = locale
   */
  document.querySelector('html').setAttribute('lang', locale)
  nextTick(() => {
    document.getElementById('agent_store').setAttribute('data-lang', locale)
  })
}
export async function setLocale(i18n, locale) {
  if (locale !== 'zh-cn') {
    const r = await getAppLocale({
      locale: locale.replace('-', '_')
    })
    if (r.code === 0) {
      let messages = i18n.global.getLocaleMessage(locale)
      messages = Object.assign(messages, r.data)
      i18n.global.setLocaleMessage(locale, messages)
      console.log(r)
    }
  }
  if (document.domain !== 'localhost') {
    Cookies.set(globalLocaleCookieKey, locale, {
      domain: '.' + process.env.VUE_APP_DOMAIN,
      path: '/'
    })
  } else {
    Cookies.set(localeCookieKey, locale)
  }
  return nextTick()
}
window.locateInit = false
export async function initLocale(locale) {
  if (!window.locateInit) {
    window.locateInited = true
    if (locale !== 'zh-cn') {
      await setLocale(i18n, locale)
    }
    setI18nLanguage(i18n, locale)
  }
}
const numberFormats = {
  'en-us': {
    currency: {
      style: 'currency',
      currency: 'USD'
    }
  },
  'ja-jp': {
    currency: {
      style: 'currency',
      currency: 'JPY',
      currencyDisplay: 'symbol'
    }
  },
  'zh-cn': {
    currency: {
      style: 'currency',
      currency: 'CNY',
      currencyDisplay: 'symbol'
    }
  }
}
const i18n = createI18n({
  numberFormats,
  legacy: false,
  globalInjection: true,
  locale: 'zh-cn',
  fallbackLocale: 'zh-cn',
  messages: loadLocaleMessages()
})
// ElementLocale.i18n
export default i18n
